.scale-cover {
  img {
    transition: 0.35s ease transform;

    @include hocus() {
      transform: scale(1.1);
    }
  }

  &.w-opacity {
    img {
      transition: 0.35s ease all;

      @include hocus() {
        opacity: 0.5;
        transform: scale(1.1);
      }
    }
  }
}
