/**
 1st
 * Include styles for individual pages
 *
 */
@import './pages/about-us';
@import 'components/hero-video';
@import 'components/swiper-tabs-with-timer';
@import 'components/quote/quote-crumbs';
@import 'components/quote/pricing-cart';
@import 'components/quote/ribbon-badge';
@import 'components/quote/tooltip';
@import 'components/heading-xxl-large';
@import 'components/footer';
@import 'components/banner-hero';

/**
 * Include vendor css
 *
 */

/*! purgecss start ignore */

@import '../../node_modules/swiper/css/swiper.css';
@import '../../node_modules/tippy.js/dist/tippy.css';
@import '../../node_modules/slim-select/dist/slimselect.min.css';
@import 'vendors/swiper';
@import 'vendors/swiper-industries';
@import 'vendors/swiper-technology';
@import 'vendors/swiper-about';
@import 'vendors/swiper-benefits';
@import 'vendors/plyr';
@import 'vendors/dropzone';
@import 'vendors/tippy';
@import 'vendors/slim-select';
@import 'vendors/cookie-consent';

/*! purgecss end ignore */

/**
 * Include themes styles
 *
 */
@import 'theme/wordminds';

/**
 * Include states
 *
 */
@import 'base/states';
