/* stylelint-disable */

input[type='text'],
input[type='email'],
input[type='password'],
input[type='url'],
input[type='tel'],
input[type='number'],
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline-width: 2px;
    outline-style: auto;
    outline-color: #4d90fe;
    outline-offset: -2px;
  }
}

textarea {
  padding: 0.25rem 1rem;
  line-height: 1.5;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@responsive {
  .form {
    &--light-theme {
      .input-field,
      .ss-main {
        background-color: theme('colors.gray.50');
      }
    }

    &--dark-theme {
      .input-field,
      .ss-main {
        background-color: #fff;
      }
    }
  }

  .input-field {
    padding: 0 1rem;
    min-height: rem(40px);
    font-size: rem(14px);
    line-height: rem(38px);
    border: 1px solid transparent;
    border-radius: rem(2px);
  }

  textarea.input-field {
    height: auto;
    min-height: 150px;
    line-height: 1.5;
    padding: 0.25rem 1rem;
  }

  .input-field--light-theme {
    background-color: theme('colors.gray.50');

    .error {
      border: 1px solid theme('colors.red.default');
    }
  }

  .input-field--light-theme[type='checkbox']:not(:checked) + .checkbox-simple:before {
     background-color: theme('colors.gray.50');
   }

  .input-field--light-theme:hover {
    border: 1px solid theme('colors.gray.default');
  }

  .input-field--light-theme:focus {
    border: 1px solid theme('colors.blue.50');
  }

  .input-field--dark-theme {
    background-color: theme('colors.white.default');

    .error {
      border: 1px solid theme('colors.red.default');
    }
  }

  .input-field--dark-theme:focus,
  .input-field--dark-theme:hover {
    border: 1px solid theme('colors.blue.50');
  }
}

@responsive {
  .checkbox {
    position: relative;
    padding-left: rem(36px);
  }

  .checkbox::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    content: '';
    background-color: theme('colors.white.default');
    border: 1px solid theme('colors.gray.default');
    border-radius: 2px;
    transform: translate3d(0, -50%, 0);
  }

  .checkbox--20 {
    padding-left: rem(36px);
  }

  .checkbox--20::before {
    width: 20px;
    height: 20px;
  }

  .checkbox--30 {
    padding-left: rem(46px);
  }

  .checkbox--30::before {
    width: 30px;
    height: 30px;
  }

  .checkbox-simple {
    position: relative;
    padding-left: rem(36px);
    display: block;
  }

  .checkbox-simple::before {
    position: absolute;
    top: 10px;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    content: '';
    background-color: theme('colors.white.default');
    border: 1px solid theme('colors.gray.default');
    border-radius: 2px;
    transform: translate3d(0, -50%, 0);
  }

  .checkbox-inversed::before {
    background-color: transparent;
    border: 1px solid;
  }

  .checkbox-shadow {
    label {
      opacity: 0.5;
    }

    &:hover {
      label {
        opacity: 1;
      }
    }

    .checkbox:checked + label {
      opacity: 1;
    }
  }
}

input[type='checkbox']:checked + label::before {
  background-color: theme('colors.blue.default');
  border: 1px solid theme('colors.blue.default');
  background-image: url('../../assets/svg/check-icon-simple.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% auto;
}

input[type='checkbox']:checked + label::after {
  //display: block;
}

input[type='checkbox']:checked + label.checkbox-inversed::before {
  background-color: theme('colors.orange.default');
  border: 1px solid theme('colors.orange.default');
}

label.checkbox-inversed {
  &.required {
    &::after,
    .text-red {
      color: white;
    }
  }
}

input[type='file']::-webkit-file-upload-button {
  margin: 0;
  padding: 0;
  width: 0;
  border: none;
  -webkit-appearance: none;
}

input[type='file']:focus + label {
  outline: 1px solid #0078d7;
}

select.input-field--light-theme {
  color: theme('colors.black.50');
  appearance: none;

  &:focus {
    color: theme('colors.black.default');
  }
}

.select-wrap {
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    right: 15px;
    display: block;
    width: 16px;
    height: 12px;
    content: '';
    background-image: url('../../assets/svg/arrow-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid transparent;
    transform: translate3d(0, -40%, 0);
  }

  &:hover {
    &::after {
      //border-right-color: theme('colors.black.50'); //border-bottom-color: theme('colors.black.50');
    }
  }
}

label {
  //&:not(.is-short) {
  //  white-space: nowrap;
  //}

  a {
    display: inline;
    color: theme('colors.orange.70');

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &.required:not(.checkbox-simple) {
    display: inline;
    padding-right: rem(12px);
    position: relative;

    &::after {
      padding-left: 3px;
      content: '*';
      color: theme('colors.red.default');
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.custom-select {
  &__item {
    padding: rem(4px) 1rem;

    &:not(.checked) {
      &:hover {
        color: #fff;
        background-color: #1e90ff;
      }
    }
  }

  .custom-select-toggler {
    text-align: left;
    color: theme('colors.black.50');

    &:focus {
      color: theme('colors.black.default');
    }

    &.is-active {
      border: 1px solid #1e90ff;
    }
  }

  .selected-wrapper {
    position: absolute;
    z-index: 3;
    top: 100%;
    left: 0;
    overflow: hidden;
    width: 100%;
    max-height: rem(145px);
    background-color: theme('colors.gray.50');
    border: 1px solid #1e90ff;
    border-top: none;

    &__inner {
      overflow-y: auto;
      max-height: rem(145px);
    }
  }

  .checked {
    background-color: theme('colors.gray.default');
  }
}

input[data-name='has-dropdown'] + label + .hidden-input {
  margin-top: 0;
  overflow: hidden;
  max-height: 0;
  transition: 0.2s ease-in-out;
}

input[data-name='has-dropdown']:checked + label + .hidden-input {
  margin-top: 10px;
  max-height: 150px;
}

/* clears the 'X' from IE */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/* disable arrows of number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

form.is-loading {
  .spinner::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2.5rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    content: '';
    background-image: url('../../assets/svg/spinner-orange.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@screen lg {
  .form-group.is-locked {
    position: relative;

    &:after {
      content: '';
      display: block;
      top: 34px;
      left: 0;
      width: 100%;
      height: 2.5rem;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      cursor: not-allowed;
    }
  }
}

.wordminds-site {
  @responsive {
    .input-field--light-theme {
      background-color: theme('colors.blue.wslight');
    }
  }

  label a {
    color: theme('colors.green.wslight');
  }

  input[type='checkbox']:checked + label::before,
  input[type='checkbox']:checked + label.checkbox-inversed::before {
    background-color: theme('colors.green.wslight');
    border: 1px solid theme('colors.green.wslight');
  }
}
