.tippy-box[data-theme='travod'] {
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: left;
  color: theme('colors.black.default');
  background-color: #fff;
  box-shadow: theme('boxShadow.default');

  .tippy-content {
    padding: 14px 18px 16px 16px;
  }
}

.tippy-box[data-theme='travod-tiny'] {
  font-size: 10px;
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: left;
  color: theme('colors.black.default');
  background-color: #fff;
  box-shadow: theme('boxShadow.default');

  .tippy-content {
    padding: 7px 9px 8px 8px;
  }
}

@media (max-width: theme('screens.lg')) {
  .tippy-box[data-theme~='travod-tiny'] {
    display: none;
  }
}

.tippy-box[data-theme~='travod'][data-placement^='top'],
.tippy-box[data-theme~='travod-tiny'][data-placement^='top'] {
  > .tippy-arrow::before {
    border-top-color: #fff;
  }
}

.tippy-box[data-theme~='travod'][data-placement^='bottom'],
.tippy-box[data-theme~='travod-tiny'][data-placement^='bottom'] {
  > .tippy-arrow::before {
    border-bottom-color: #fff;
  }
}

.tippy-box[data-theme~='travod'][data-placement^='left'],
.tippy-box[data-theme~='travod-tiny'][data-placement^='left'] {
  > .tippy-arrow::before {
    border-left-color: #fff;
  }
}

.tippy-box[data-theme~='travod'][data-placement^='right'],
.tippy-box[data-theme~='travod-tiny'][data-placement^='right'] {
  > .tippy-arrow::before {
    border-right-color: #fff;
  }
}
