/* stylelint-disable */
/*! purgecss start ignore */

.text-block {
  > * + * {
    margin-top: rem(24px);
  }

  > h2 + p,
  > h2 + h2,
  > h2 + h3,
  > h2 + h4,
  > .lead + h2 {
    margin-top: rem(20px);
  }

  > h3 + p,
  > h3 + h4,
  > h3 + h2,
  > h3 + h3,
  > h4 + h2,
  > h4 + h3,
  > h4 + h4,
  > .lead + h3,
  > .lead + h4 {
    margin-top: rem(15px);
  }

  > h4 + p {
    margin-top: rem(10px);
  }

  > * + h2,
  > * + h3,
  > * + strong,
  > * + blockquote:not(.is-centered),
  > * + .carousel,
  > .carousel + *,
  > * + figure,
  > figure + *,
  > * + .table-block,
  > .table-block + * {
    margin-top: rem(40px);
  }

  h2 {
    @apply font-bold text-36p leading-111;
  }

  h3 {
    @apply font-semibold text-20p leading-125;
  }

  h4 {
    @apply font-semibold text-16p leading-125;
  }

  &.blue-headings {
    h2,
    h3,
    h4 {
      @apply text-blue;
    }
  }

  &--tiny {
    > * + * {
      margin-top: rem(20px);
    }
  }

  ul,
  ol {
    li + li,
    li > ul {
      margin-top: 2px;
    }

    li {
      position: relative;
      align-items: center;
      line-height: 24px;

      &:before {
        content: '●';
        display: inline-block;
        color: theme('colors.blue.default');
        margin-right: 8px;
        line-height: 24px;
      }

      > ul {
        padding-left: 16px;
      }
    }

    li a {
      color: theme('colors.orange.default');
      text-decoration: underline;

      &:hover,
      &:focus {
        color: theme('colors.orange.90');
      }
    }
  }

  ol {
    padding-left: 0;
    counter-reset: section;
    list-style-type: none;

    li {
      &:before {
        counter-increment: section;
        content: counters(section, '.') '.';
      }
    }
  }

  a {
    color: theme('colors.orange.default');
    text-decoration: underline;

    &:hover,
    &:focus {
      color: theme('colors.orange.90');
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    a {
      text-decoration: none;
      color: inherit;

      &:hover,
      &:focus {
        color: theme('colors.orange.90');
        text-decoration: none;
      }
    }
  }

  .lead {
    font-size: 18px;
    line-height: 1.44;
    font-weight: 700;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    font-size: rem(14px);

    thead {
      border-radius: 4px 4px 0 0;
      border: 1px solid theme('colors.blue.default');
      border-bottom: none;
      overflow: hidden;
    }

    tbody {
      //display: block;
      border-radius: 0 0 4px 4px;
      border: 1px solid theme('colors.gray.default');
      border-top: none;
      overflow: hidden;
    }

    th {
      background-color: theme('colors.blue.default');
      color: #fff;
      font-weight: bold;
    }

    td,
    th {
      padding: 16px 20px;
      text-align: center;
    }

    tr td:first-child {
      border-left: 1px solid theme('colors.gray.default');
    }

    tr td:last-child {
      border-right: 1px solid theme('colors.gray.default');
    }

    tr:last-child td {
      border-bottom: 1px solid theme('colors.gray.default');
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }

    th:first-child {
      border-top-left-radius: 4px;
    }

    th:last-child {
      border-top-right-radius: 4px;
    }

    thead + tbody {
      th:first-child {
        border-top-left-radius: 0;
      }

      th:last-child {
        border-top-right-radius: 0;
      }
    }

    tr:nth-child(even) td {
      background-color: theme('colors.gray.30');
    }

    td:before {
      content: '';
      display: block;
      width: 3rem;
    }
  }

  h2 + .table-block,
  h3 + .table-block,
  h4 + .table-block,
  .lead + .table-block {
    margin-top: 1rem;
  }

  .table-block {
    overflow-x: auto;
    overflow-y: hidden;

    @media (max-width: theme('screens.lg')) {
      margin-right: -20px;
      margin-left: -20px;
      padding-left: 20px;
      padding-right: 20px;

      table {
        padding-right: 20px;
      }
    }
  }

  @media (min-width: theme('screens.lg')) {
    > * + h2,
    > * + h3,
    > * + strong,
    > * + blockquote:not(.is-centered),
    > * + .carousel,
    > .carousel + *,
    > * + figure,
    > figure + *,
    > * + .table-block,
    > .table-block + * {
      margin-top: rem(60px);
    }
  }

  @screen lg {
    > * + .table-block,
    > .table-block + * {
      margin-top: rem(60px);
    }
  }

  &-light {
    > * + * {
      margin-top: rem(40px);
    }

    > h3 + ul,
    > h3 + ol,
    > h4 + ul,
    > h4 + ol {
      margin-top: rem(10px);
    }

    .lead {
      font-weight: normal;
    }

    a {
      text-decoration: none;
    }
  }
}

.wordminds-site {
  .text-block {
    &.blue-headings {
      h2,
      h3,
      h4 {
        color: theme('colors.blue.wsdark');
      }
    }

    .lead .text-orange-60 {
      color: theme('colors.green.wslight');
    }

    ul,
    ol {
      li {
        &:before {
          color: theme('colors.green.wslight');
        }

        > ul {
          padding-left: 16px;
        }
      }

      li a {
        color: theme('colors.green.wslight');

        &:hover,
        &:focus {
          color: theme('colors.green.wsnormal');
        }
      }
    }

    a {
      color: theme('colors.green.wslight');

      &:hover,
      &:focus {
        color: theme('colors.green.wsnormal');
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      a {
        &:hover,
        &:focus {
          color: theme('colors.green.wslight');
        }
      }
    }

    .lead {
      font-size: 18px;
      line-height: 1.44;
      font-weight: 700;
    }

    table {
      thead {
        border: 1px solid theme('colors.green.wslight');
      }

      th {
        background-color: theme('colors.green.wslight');
      }
    }
  }
}
/*! purgecss end ignore */
