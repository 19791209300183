/* stylelint-disable */

#elc-cookie-consent {
  position: fixed;
  z-index: 10000;
  right: 8em;
  bottom: 1em;
  padding: 2em;
  overflow: auto;
  max-width: calc(100% - 9em);
  max-height: calc(100% - 9em);
  font-size: 0.8em;
  background-color: #fff;
  border-radius: 0.3em;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.elc-small {
  width: 30em;
}

#elc-cookie-consent h3 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

#elc-cookie-consent h4 {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.elc-fullwidth h4 {
  font-size: 1.4em;
  font-weight: bold;
}

#elc-cookie-consent .button-container {
  margin-top: 2rem;
  clear: both;
  text-align: right;
}

.elc-fullwidth {
  width: calc(100% - 6em) !important;
}

#elc-cookie-consent .elc-button-link {
  position: relative;
  padding: calc(0.375em - 1px) 0.75em;
  display: inline-block;
  width: 50px;
  height: 2.25em;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;
  color: #fff;
  background-color: theme('colors.green.70');
  border-width: 1px;
  border-color: transparent;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
}

#elc-cookie-consent .elc-secondary-button-link {
  position: relative;
  padding: calc(0.375em - 1px) 0.75em;
  display: inline-block;
  height: 2.25em;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;
  color: #2c3041;
  background-color: #fff;
  border-width: 1px;
  border-color: #0e3a5e;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
}

#elc-cookie-consent .elc-text-link {
  margin-right: 1em;
  line-height: 3.8em;
  text-transform: uppercase;
  color: #2c3041;
}

#elc-cookie-consent .elc-button-link {
  width: 8em;
  text-transform: uppercase;
}

#elc-cookie-consent .elc-cookie-group {
  padding-right: 1em;
  float: left;
}

#elc-cookie-consent .elc-header {
  font-size: 1.1em;
}

.elc-fullwidth .elc-cookie-group {
  width: 100%;
}

#elc-cookie-consent .elc-row {
  clear: both;
  width: 100%;
}

#elc-cookie-consent #elc-cookie-consent-settings {
  margin-bottom: 4em;
}

#elc-cookie-consent .elc-row .elc-cookie-name {
  float: left;
}

#elc-cookie-consent .elc-row .elc-cookie-checkbox {
  float: right;
}

#elc-cookie-consent .elc-cookie-description {
  margin-bottom: 1em;
  padding-top: 1em;
  display: none;
  border-top: 1px solid #ccc;
}

.elc-fullwidth .elc-cookie-description {
  display: block !important;
}

#elc-cookie-consent .elc-cookie {
  margin-bottom: 1em;
}

#elc-cookie-consent .elc-cookie-field {
  display: none;
}

.elc-fullwidth .elc-cookie-field {
  display: block !important;
}

.elc-fullwidth #elc-detail-link {
  display: none;
}

.elc-fullwidth #elc-accept-link {
  display: none;
}

.elc-small #elc-hide-detail-link {
  display: none;
}

.elc-small #elc-save-link {
  display: none;
}

.elc-small .elc-hide-when-small {
  display: none;
}

.elc-hidden {
  display: none;
}

#elc-cookie-tab {
  position: fixed;
  z-index: 10000;
  right: 0;
  bottom: 0;
  padding: 0.2em 1em;
  font-size: 0.8em;
  background-color: theme('colors.green.70');
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

#elc-cookie-tab a {
  color: #fff;
}

#elc-cookie-consent input[type='checkbox' i] {
  -webkit-appearance: checkbox;
  box-sizing: border-box;
}

#elc-cookie-consent input[type='checkbox' i] {
  margin: 3px 3px 3px 0.6em;
  padding: initial;
  background-color: initial;
  border: initial;
  cursor: default;
}
