.iso-label {
  padding-right: 15px;
  padding-left: 15px;

  svg {
    margin-right: 6px;
  }

  /* &:before {
		//@include pseudo();
		//left: 12px;
		//top: -1px;
		//height: rem(13px);
		//width: rem(13px);
		//background-color: $blue;
		//border-radius: 50%;

		//@screen lg {
		//	top: 0;
		//}
	} */
}
