/* stylelint-disable */

.form-group,
.form-group-1,
.form-group-2 {
  .text-help {
    display: none;
  }

  &.has-error,
  &.has-danger {
    .input-field {
      border-color: theme('colors.red.default');

      &:hover {
        border-color: theme('colors.red.default') !important;
      }
    }

    .text-help,
    .help-block {
      margin-top: 0.5em;
      display: block;
      font-size: 1em;
      color: theme('colors.red.default');
    }

    input[type='checkbox'] + label::before {
      border: 1px solid theme('colors.red.default');
    }
  }
}

.inversed-form {
  .form-group,
  .form-group-1,
  .form-group-2 {
    .text-help {
      display: none;
    }

    &.has-error,
    &.has-danger {
      .input-field {
        border-color: theme('colors.red.default');

        &:hover {
          border-color: theme('colors.red.default') !important;
        }
      }

      .text-help,
      .help-block {
        display: block;
        color: #fff;
      }

      input[type='checkbox'] + label::before {
        border: 1px solid #fff;
      }

      input[type='text'].input-field,
      input[type='number'].input-field,
      input[type='email'].input-field,
      input[type='tel'].input-field,
      textarea.input-field {
        background-color: theme('colors.red.10');
      }
    }
  }
}
