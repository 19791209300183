.tag-swither {
  @media (max-width: 767px) {
    position: fixed;
    z-index: 50;
    right: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}
