.services-we-choose {
  background-image: url('../../assets/travod-words-bg-sm.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 321px 518px;

  @media (min-width: theme('screens.lg')) {
    background-image: url('../../assets/svg/oval-bg.svg'), url('../../assets/travod-words-bg.png');
    background-position: left calc(50% + 300px) top 300px, left calc(50% + 490px) top 5px;
    background-size: 1280px, 428px 771px;
  }
}

.services-we-choose__item {
  &.is-active.is-last {
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      display: block;
      width: calc(100vw - 40px);
      height: 2px;
      content: '';
      background: theme('colors.gray.default');

      @screen lg {
        display: none;
      }
    }
  }

  &.is-active {
    padding-bottom: 1.25rem;
    background-color: theme('colors.white.default');

    @media (min-width: theme('screens.lg')) {
      padding-bottom: 3.125rem;
      display: block;
      box-shadow: 0 4px 30px -4px rgba(0, 0, 0, 0.1);
      transform: unset;
    }
  }

  &.is-first,
  &.is-active + & {
    .services-we-choose__title {
      border-top-color: transparent;
    }
  }
}

.services-we-choose__title {
  border-top: 2px solid theme('colors.gray.default');

  &.is-active {
    border-top-color: transparent;

    & > span {
      transform: rotate(-180deg);
    }
  }
}

.wordminds-site {
  .services-we-choose {
    background-image: none;
  }
}
