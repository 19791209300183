.appearance-none {
  appearance: none
}

.bg-transparent {
  background-color: transparent
}

.bg-black-30 {
  background-color: rgba(44,48,65,.3)
}

.bg-black {
  --bg-opacity: 1;
  background-color: #2c3041;
  background-color: rgba(44, 48, 65, var(--bg-opacity))
}

.bg-white-10 {
  background-color: rgba(255, 255, 255, .1)
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-gray-30 {
  --bg-opacity: 1;
  background-color: #f7f7f8;
  background-color: rgba(247, 247, 248, var(--bg-opacity))
}

.bg-gray-50 {
  --bg-opacity: 1;
  background-color: #f2f3f3;
  background-color: rgba(242, 243, 243, var(--bg-opacity))
}

.bg-gray {
  --bg-opacity: 1;
  background-color: #e6e7e8;
  background-color: rgba(230, 231, 232, var(--bg-opacity))
}

.bg-red-10 {
  --bg-opacity: 1;
  background-color: #fce9ec;
  background-color: rgba(252, 233, 236, var(--bg-opacity))
}

.bg-red {
  --bg-opacity: 1;
  background-color: #e82942;
  background-color: rgba(232, 41, 66, var(--bg-opacity))
}

.bg-orange {
  --bg-opacity: 1;
  background-color: #f7941d;
  background-color: rgba(247, 148, 29, var(--bg-opacity))
}

.bg-yellow {
  --bg-opacity: 1;
  background-color: #ffc212;
  background-color: rgba(255, 194, 18, var(--bg-opacity))
}

.bg-green-10 {
  --bg-opacity: 1;
  background-color: #eaf7f1;
  background-color: rgba(234, 247, 241, var(--bg-opacity))
}

.bg-green {
  --bg-opacity: 1;
  background-color: #2db674;
  background-color: rgba(45, 182, 116, var(--bg-opacity))
}

.bg-green-wslight {
  --bg-opacity: 1;
  background-color: #51AD32;
  background-color: rgba(81, 173, 50, var(--bg-opacity))
}

.bg-green-wsnormal {
  --bg-opacity: 1;
  background-color: #2f9638;
  background-color: rgba(47, 150, 56, var(--bg-opacity))
}

.bg-blue-10 {
  --bg-opacity: 1;
  background-color: #e8edfe;
  background-color: rgba(232, 237, 254, var(--bg-opacity))
}

.bg-blue {
  --bg-opacity: 1;
  background-color: #194ef5;
  background-color: rgba(25, 78, 245, var(--bg-opacity))
}

.bg-blue-wsdark {
  --bg-opacity: 1;
  background-color: #183E7A;
  background-color: rgba(24, 62, 122, var(--bg-opacity))
}

.bg-blue-wslight {
  --bg-opacity: 1;
  background-color: #F5F7FC;
  background-color: rgba(245, 247, 252, var(--bg-opacity))
}

.bg-footer-bottom {
  --bg-opacity: 1;
  background-color: #292d3e;
  background-color: rgba(41, 45, 62, var(--bg-opacity))
}

.bg-navy {
  --bg-opacity: 1;
  background-color: #7100d5;
  background-color: rgba(113, 0, 213, var(--bg-opacity))
}

.bg-deepSky {
  --bg-opacity: 1;
  background-color: #00adee;
  background-color: rgba(0, 173, 238, var(--bg-opacity))
}

.bg-froly {
  --bg-opacity: 1;
  background-color: #f16279;
  background-color: rgba(241, 98, 121, var(--bg-opacity))
}

.bg-fuchsia {
  --bg-opacity: 1;
  background-color: #d70081;
  background-color: rgba(215, 0, 129, var(--bg-opacity))
}

.bg-royalBlue {
  --bg-opacity: 1;
  background-color: #7551e9;
  background-color: rgba(117, 81, 233, var(--bg-opacity))
}

.bg-deepMondiaViolet {
  --bg-opacity: 1;
  background-color: #150c2e;
  background-color: rgba(21, 12, 46, var(--bg-opacity))
}

.bg-fuchsiaDark {
  --bg-opacity: 1;
  background-color: #a70267;
  background-color: rgba(167, 2, 103, var(--bg-opacity))
}

.hover\:bg-white:hover {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.hover\:bg-gray-60:hover {
  --bg-opacity: 1;
  background-color: #cbced0;
  background-color: rgba(203, 206, 208, var(--bg-opacity))
}

.hover\:bg-orange:hover {
  --bg-opacity: 1;
  background-color: #f7941d;
  background-color: rgba(247, 148, 29, var(--bg-opacity))
}

.hover\:bg-green-wslight:hover {
  --bg-opacity: 1;
  background-color: #51AD32;
  background-color: rgba(81, 173, 50, var(--bg-opacity))
}

.hover\:bg-blue:hover {
  --bg-opacity: 1;
  background-color: #194ef5;
  background-color: rgba(25, 78, 245, var(--bg-opacity))
}

.hover\:bg-blue-wsdark:hover {
  --bg-opacity: 1;
  background-color: #183E7A;
  background-color: rgba(24, 62, 122, var(--bg-opacity))
}

.focus\:bg-white:focus {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.group:hover .group-hover\:bg-green-wsnormal {
  --bg-opacity: 1;
  background-color: #2f9638;
  background-color: rgba(47, 150, 56, var(--bg-opacity))
}

.group:hover .group-hover\:bg-blue {
  --bg-opacity: 1;
  background-color: #194ef5;
  background-color: rgba(25, 78, 245, var(--bg-opacity))
}

.border-collapse {
  border-collapse: collapse
}

.border-white-10 {
  border-color: rgba(255, 255, 255, .1)
}

.border-white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.border-red-30 {
  --border-opacity: 1;
  border-color: #f8bec6;
  border-color: rgba(248, 190, 198, var(--border-opacity))
}

.border-green-30 {
  --border-opacity: 1;
  border-color: #c0e9d5;
  border-color: rgba(192, 233, 213, var(--border-opacity))
}

.border-footer-line-10 {
  border-color: rgba(230, 231, 232, .1)
}

.rounded-50 {
  border-radius: 50%
}

.rounded-sm {
  border-radius: 0.125rem
}

.rounded {
  border-radius: 0.25rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-24p {
  border-radius: 1.5rem
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem
}

.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.border-solid {
  border-style: solid
}

.border {
  border-width: 1px
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-l {
  border-left-width: 1px
}

.box-border {
  box-sizing: border-box
}

.cursor-pointer {
  cursor: pointer
}

.cursor-not-allowed {
  cursor: not-allowed
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.self-start {
  align-self: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}

.order-first {
  order: -9999
}

.order-last {
  order: 9999
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-0 {
  height: 0
}

.h-3 {
  height: 0.75rem
}

.h-4 {
  height: 1rem
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.h-10 {
  height: 2.5rem
}

.h-12 {
  height: 3rem
}

.h-16 {
  height: 4rem
}

.h-40 {
  height: 10rem
}

.h-auto {
  height: auto
}

.h-20p {
  height: 1.25rem
}

.h-26p {
  height: 1.625rem
}

.h-30p {
  height: 1.875rem
}

.h-35p {
  height: 2.188rem
}

.h-40p {
  height: 2.5rem
}

.h-50p {
  height: 3.125rem
}

.h-60p {
  height: 3.75rem
}

.h-full {
  height: 100%
}

.text-xs {
  font-size: 0.75rem
}

.text-sm {
  font-size: 0.875rem
}

.text-base {
  font-size: 1rem
}

.text-lg {
  font-size: 1.125rem
}

.text-xl {
  font-size: 1.25rem
}

.text-2xl {
  font-size: 1.5rem
}

.text-11p {
  font-size: 0.6875rem
}

.text-12p {
  font-size: 0.75rem
}

.text-14p {
  font-size: 0.875rem
}

.text-16p {
  font-size: 1rem
}

.text-18p {
  font-size: 1.125rem
}

.text-20p {
  font-size: 1.25rem
}

.text-24p {
  font-size: 1.5rem
}

.text-36p {
  font-size: 2.25rem
}

.text-54p {
  font-size: 3.375rem
}

.leading-111 {
  line-height: 1.11
}

.leading-125 {
  line-height: 1.25
}

.leading-143 {
  line-height: 1.43
}

.leading-144 {
  line-height: 1.44
}

.leading-150 {
  line-height: 1.5
}

.leading-167 {
  line-height: 1.67
}

.leading-214 {
  line-height: 2.14
}

.leading-tight {
  line-height: 1.25
}

.leading-38p {
  line-height: 2.375rem
}

.m-1 {
  margin: 0.25rem
}

.m-4 {
  margin: 1rem
}

.m-auto {
  margin: auto
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-20p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.mx-20p {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.my-30p {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem
}

.my-40p {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.-mx-5p {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem
}

.-mx-10p {
  margin-left: -0.625rem;
  margin-right: -0.625rem
}

.-mx-20p {
  margin-left: -1.25rem;
  margin-right: -1.25rem
}

.-mx-30p {
  margin-left: -1.875rem;
  margin-right: -1.875rem
}

.mb-0 {
  margin-bottom: 0
}

.mr-1 {
  margin-right: 0.25rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mt-auto {
  margin-top: auto
}

.mr-auto {
  margin-right: auto
}

.mb-auto {
  margin-bottom: auto
}

.ml-auto {
  margin-left: auto
}

.mt-5p {
  margin-top: 0.3125rem
}

.mb-5p {
  margin-bottom: 0.3125rem
}

.mt-6p {
  margin-top: 0.375rem
}

.ml-8p {
  margin-left: 0.5rem
}

.mt-10p {
  margin-top: 0.625rem
}

.mr-10p {
  margin-right: 0.625rem
}

.mb-10p {
  margin-bottom: 0.625rem
}

.ml-10p {
  margin-left: 0.625rem
}

.mt-14p {
  margin-top: 0.875rem
}

.mr-14p {
  margin-right: 0.875rem
}

.ml-14p {
  margin-left: 0.875rem
}

.mt-15p {
  margin-top: 0.9375rem
}

.mb-15p {
  margin-bottom: 0.9375rem
}

.mt-20p {
  margin-top: 1.25rem
}

.mr-20p {
  margin-right: 1.25rem
}

.mb-20p {
  margin-bottom: 1.25rem
}

.ml-20p {
  margin-left: 1.25rem
}

.mb-28p {
  margin-bottom: 1.75rem
}

.mt-30p {
  margin-top: 1.875rem
}

.mb-30p {
  margin-bottom: 1.875rem
}

.mt-40p {
  margin-top: 2.5rem
}

.mb-40p {
  margin-bottom: 2.5rem
}

.mt-60p {
  margin-top: 3.75rem
}

.mb-60p {
  margin-bottom: 3.75rem
}

.-mt-5p {
  margin-top: -0.3125rem
}

.-ml-6p {
  margin-left: -0.375rem
}

.-mt-10p {
  margin-top: -0.625rem
}

.-mb-10p {
  margin-bottom: -0.625rem
}

.-mb-15p {
  margin-bottom: -0.9375rem
}

.-mt-20p {
  margin-top: -1.25rem
}

.-mb-20p {
  margin-bottom: -1.25rem
}

.-mt-40p {
  margin-top: -2.5rem
}

.-mb-40p {
  margin-bottom: -2.5rem
}

.-mt-60p {
  margin-top: -3.75rem
}

.-mt-80p {
  margin-top: -5rem
}

.-mt-100p {
  margin-top: -6.25rem
}

.-mt-160p {
  margin-top: -10rem
}

.-mt-250p {
  margin-top: -15.625rem
}

.mb-18p {
  margin-bottom: 1.125rem
}

.group:hover .group-hover\:mb-20p {
  margin-bottom: 1.25rem
}

.max-h-30p {
  max-height: 1.875rem
}

.max-h-50p {
  max-height: 3.125rem
}

.max-h-100p {
  max-height: 6.25rem
}

.max-h-370p {
  max-height: 23.13rem
}

.max-w-none {
  max-width: none
}

.max-w-sm {
  max-width: 24rem
}

.max-w-md {
  max-width: 28rem
}

.max-w-lg {
  max-width: 32rem
}

.max-w-xl {
  max-width: 36rem
}

.max-w-full {
  max-width: 100%
}

.max-w-6\/12 {
  max-width: 50%
}

.max-w-8\/12 {
  max-width: 66.66666666666667%
}

.max-w-10\/12 {
  max-width: 83.33333333333333%
}

.max-w-120p {
  max-width: 7.5rem
}

.max-w-260p {
  max-width: 16.25rem
}

.max-w-320p {
  max-width: 20rem
}

.max-w-340p {
  max-width: 21.25rem
}

.max-w-400p {
  max-width: 25rem
}

.max-w-440p {
  max-width: 27.5rem
}

.max-w-640p {
  max-width: 40rem
}

.max-w-700p {
  max-width: 43.75rem
}

.max-w-720p {
  max-width: 45rem
}

.max-w-900p {
  max-width: 56.25rem
}

.max-w-1080p {
  max-width: 67.5rem
}

.max-w-1100p {
  max-width: 68.75rem
}

.max-w-1400p {
  max-width: 87.5rem
}

.min-h-screen {
  min-height: 100vh
}

.min-h-40p {
  min-height: 2.5rem
}

.min-h-150p {
  min-height: 9.375rem
}

.min-h-300p {
  min-height: 18.75rem
}

.object-cover {
  object-fit: cover
}

.object-center {
  object-position: center
}

.opacity-0 {
  opacity: 0
}

.opacity-30 {
  opacity: .3
}

.opacity-40 {
  opacity: .4
}

.opacity-50 {
  opacity: .5
}

.opacity-60 {
  opacity: .6
}

.opacity-75 {
  opacity: .75
}

.opacity-100 {
  opacity: 1
}

.hover\:opacity-50:hover {
  opacity: .5
}

.hover\:opacity-100:hover {
  opacity: 1
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-hidden {
  overflow-x: hidden
}

.p-5 {
  padding: 1.25rem
}

.p-2p {
  padding: 0.125rem
}

.p-10p {
  padding: 0.625rem
}

.p-15p {
  padding: 0.9375rem
}

.p-20p {
  padding: 1.25rem
}

.p-30p {
  padding: 1.875rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.py-5p {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem
}

.px-5p {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem
}

.py-10p {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem
}

.px-10p {
  padding-left: 0.625rem;
  padding-right: 0.625rem
}

.py-15p {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem
}

.px-15p {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem
}

.py-20p {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.px-20p {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-30p {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem
}

.px-30p {
  padding-left: 1.875rem;
  padding-right: 1.875rem
}

.py-35p {
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem
}

.py-40p {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.py-60p {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem
}

.py-140p {
  padding-top: 8.75rem;
  padding-bottom: 8.75rem
}

.pt-0 {
  padding-top: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-1 {
  padding-left: 0.25rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pt-40 {
  padding-top: 10rem
}

.pt-px {
  padding-top: 1px
}

.pl-8p {
  padding-left: 0.5rem
}

.pt-10p {
  padding-top: 0.625rem
}

.pb-10p {
  padding-bottom: 0.625rem
}

.pl-10p {
  padding-left: 0.625rem
}

.pt-20p {
  padding-top: 1.25rem
}

.pr-20p {
  padding-right: 1.25rem
}

.pb-20p {
  padding-bottom: 1.25rem
}

.pl-20p {
  padding-left: 1.25rem
}

.pt-30p {
  padding-top: 1.875rem
}

.pb-30p {
  padding-bottom: 1.875rem
}

.pb-35p {
  padding-bottom: 2.1875rem
}

.pt-40p {
  padding-top: 2.5rem
}

.pb-40p {
  padding-bottom: 2.5rem
}

.pl-40p {
  padding-left: 2.5rem
}

.pt-60p {
  padding-top: 3.75rem
}

.pr-60p {
  padding-right: 3.75rem
}

.pb-60p {
  padding-bottom: 3.75rem
}

.pb-80p {
  padding-bottom: 5rem
}

.pt-120p {
  padding-top: 7.5rem
}

.pt-140p {
  padding-top: 8.75rem
}

.pt-160p {
  padding-top: 10rem
}

.pt-190p {
  padding-top: 11.875rem
}

.pb-200p {
  padding-bottom: 12.5rem
}

.pb-270p {
  padding-bottom: 16.875rem
}

.pb-6\/12 {
  padding-bottom: 50%
}

.pb-7\/12 {
  padding-bottom: 58.33333333333333%
}

.pb-8\/12 {
  padding-bottom: 66.66666666666667%
}

.pb-12\/12 {
  padding-bottom: 100%
}

.pb-14\/12 {
  padding-bottom: 116.66666666666667%
}

.placeholder-black-50::placeholder {
  color: rgba(44,48,65,.5)
}

.placeholder-white-50::placeholder {
  color: rgba(255, 255, 255, .5)
}

.placeholder-orange::placeholder {
  --placeholder-opacity: 1;
  color: #f7941d;
  color: rgba(247, 148, 29, var(--placeholder-opacity))
}

.pointer-events-none {
  pointer-events: none
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-1\/2 {
  left: 50%
}

.top-10p {
  top: 0.625rem
}

.left-10p {
  left: 0.625rem
}

.right-20p {
  right: 1.25rem
}

.top-60p {
  top: 3.75rem
}

.-bottom-56p {
  bottom: -3.5rem
}

.group:hover .group-hover\:bottom-0 {
  bottom: 0
}

.resize {
  resize: both
}

.shadow {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1)
}

.shadow-card-2 {
  box-shadow: 0 4px 30px -4px rgba(0, 0, 0, 0.1);
}

.shadow-card-3 {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

.hover\:shadow:hover {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1)
}

.hover\:shadow-card-1:hover {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1)
}

.hover\:shadow-card-3:hover {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

.focus\:shadow-card-3:focus {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

.fill-current {
  fill: currentColor
}

.stroke-current {
  stroke: currentColor
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-transparent {
  color: transparent
}

.text-black-50 {
  color: rgba(44,48,65,.5)
}

.text-black {
  --text-opacity: 1;
  color: #2c3041;
  color: rgba(44, 48, 65, var(--text-opacity))
}

.text-white-50 {
  color: rgba(255, 255, 255, .5)
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-gray-90 {
  --text-opacity: 1;
  color: #959CA0;
  color: rgba(149, 156, 160, var(--text-opacity))
}

.text-red {
  --text-opacity: 1;
  color: #e82942;
  color: rgba(232, 41, 66, var(--text-opacity))
}

.text-orange-55 {
  --text-opacity: 1;
  color: #F89400;
  color: rgba(248, 148, 0, var(--text-opacity))
}

.text-orange-60 {
  --text-opacity: 1;
  color: #F7941E;
  color: rgba(247, 148, 30, var(--text-opacity))
}

.text-orange-70 {
  --text-opacity: 1;
  color: #f99500;
  color: rgba(249, 149, 0, var(--text-opacity))
}

.text-orange {
  --text-opacity: 1;
  color: #f7941d;
  color: rgba(247, 148, 29, var(--text-opacity))
}

.text-yellow-20 {
  --text-opacity: 1;
  color: #F8C002;
  color: rgba(248, 192, 2, var(--text-opacity))
}

.text-green {
  --text-opacity: 1;
  color: #2db674;
  color: rgba(45, 182, 116, var(--text-opacity))
}

.text-green-wslight {
  --text-opacity: 1;
  color: #51AD32;
  color: rgba(81, 173, 50, var(--text-opacity))
}

.text-blue-800 {
  --text-opacity: 1;
  color: #2c3041;
  color: rgba(44, 48, 65, var(--text-opacity))
}

.text-blue {
  --text-opacity: 1;
  color: #194ef5;
  color: rgba(25, 78, 245, var(--text-opacity))
}

.text-blue-wsdark {
  --text-opacity: 1;
  color: #183E7A;
  color: rgba(24, 62, 122, var(--text-opacity))
}

.text-copyright {
  --text-opacity: 1;
  color: #9597a0;
  color: rgba(149, 151, 160, var(--text-opacity))
}

.text-navy-75 {
  --text-opacity: 1;
  color: #042FB8;
  color: rgba(4, 47, 184, var(--text-opacity))
}

.text-sapphire-dark {
  --text-opacity: 1;
  color: #051854;
  color: rgba(5, 24, 84, var(--text-opacity))
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.hover\:text-orange:hover {
  --text-opacity: 1;
  color: #f7941d;
  color: rgba(247, 148, 29, var(--text-opacity))
}

.hover\:text-yellow:hover {
  --text-opacity: 1;
  color: #ffc212;
  color: rgba(255, 194, 18, var(--text-opacity))
}

.hover\:text-blue:hover {
  --text-opacity: 1;
  color: #194ef5;
  color: rgba(25, 78, 245, var(--text-opacity))
}

.hover\:text-blue-wsdark:hover {
  --text-opacity: 1;
  color: #183E7A;
  color: rgba(24, 62, 122, var(--text-opacity))
}

.hover\:text-disable-secondary:hover {
  --text-opacity: 1;
  color: #b0b5b8;
  color: rgba(176, 181, 184, var(--text-opacity))
}

.focus\:text-orange:focus {
  --text-opacity: 1;
  color: #f7941d;
  color: rgba(247, 148, 29, var(--text-opacity))
}

.focus\:text-blue:focus {
  --text-opacity: 1;
  color: #194ef5;
  color: rgba(25, 78, 245, var(--text-opacity))
}

.group:hover .group-hover\:text-black {
  --text-opacity: 1;
  color: #2c3041;
  color: rgba(44, 48, 65, var(--text-opacity))
}

.group:hover .group-hover\:text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.group:hover .group-hover\:text-orange-60 {
  --text-opacity: 1;
  color: #F7941E;
  color: rgba(247, 148, 30, var(--text-opacity))
}

.group:hover .group-hover\:text-orange {
  --text-opacity: 1;
  color: #f7941d;
  color: rgba(247, 148, 29, var(--text-opacity))
}

.group:hover .group-hover\:text-green-wslight {
  --text-opacity: 1;
  color: #51AD32;
  color: rgba(81, 173, 50, var(--text-opacity))
}

.group:hover .group-hover\:text-blue {
  --text-opacity: 1;
  color: #194ef5;
  color: rgba(25, 78, 245, var(--text-opacity))
}

.group:hover .group-hover\:text-blue-wsdark {
  --text-opacity: 1;
  color: #183E7A;
  color: rgba(24, 62, 122, var(--text-opacity))
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.tracking-wider {
  letter-spacing: 0.05em
}

.tracking-05p {
  letter-spacing: 0.5px
}

.whitespace-no-wrap {
  white-space: nowrap
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word
}

.w-3 {
  width: 0.75rem
}

.w-4 {
  width: 1rem
}

.w-10 {
  width: 2.5rem
}

.w-auto {
  width: auto
}

.w-20p {
  width: 1.25rem
}

.w-26p {
  width: 1.625rem
}

.w-30p {
  width: 1.875rem
}

.w-40p {
  width: 2.5rem
}

.w-50p {
  width: 3.125rem
}

.w-60p {
  width: 3.75rem
}

.w-160p {
  width: 10rem
}

.w-200p {
  width: 12.5rem
}

.w-288p {
  width: 18rem
}

.w-4\/12 {
  width: 33.333333%
}

.w-5\/12 {
  width: 41.666667%
}

.w-6\/12 {
  width: 50%
}

.w-10\/12 {
  width: 83.33333333333333%
}

.w-11\/12 {
  width: 91.66666666666667%
}

.w-12\/12 {
  width: 100%
}

.w-1\/2 {
  width: 50%
}

.w-1\/5 {
  width: 20%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-1 {
  z-index: 1
}

.z-2 {
  z-index: 2
}

.z-3 {
  z-index: 3
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.gap-x-1 {
  grid-column-gap: 0.25rem;
  column-gap: 0.25rem
}

.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke
}

.transition-opacity {
  transition-property: opacity
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.duration-200 {
  transition-duration: 200ms
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 400px) {
  .mmsm\:flex-row {
    flex-direction: row
  }

  .mmsm\:justify-center {
    justify-content: center
  }

  .mmsm\:mt-0 {
    margin-top: 0
  }

  .mmsm\:ml-20p {
    margin-left: 1.25rem
  }

  .mmsm\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }
}

@media (min-width: 560px) {
  .msm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .msm\:max-w-116p {
    max-width: 7.25rem
  }

  .msm\:px-80p {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .msm\:w-auto {
    width: auto
  }

  .msm\:w-3\/12 {
    width: 25%
  }
}

@media (min-width: 640px) {
  .sm\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f2f3f3;
    background-color: rgba(242, 243, 243, var(--bg-opacity))
  }

  .sm\:bg-blue-wslight {
    --bg-opacity: 1;
    background-color: #F5F7FC;
    background-color: rgba(245, 247, 252, var(--bg-opacity))
  }

  .sm\:rounded {
    border-radius: 0.25rem
  }

  .sm\:block {
    display: block
  }

  .sm\:flex {
    display: flex
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-col {
    flex-direction: column
  }

  .sm\:items-stretch {
    align-items: stretch
  }

  .sm\:mt-0 {
    margin-top: 0
  }

  .sm\:ml-20p {
    margin-left: 1.25rem
  }

  .sm\:ml-30p {
    margin-left: 1.875rem
  }

  .sm\:mt-40p {
    margin-top: 2.5rem
  }

  .sm\:p-20p {
    padding: 1.25rem
  }

  .sm\:px-120p {
    padding-left: 7.5rem;
    padding-right: 7.5rem
  }

  .sm\:pb-60p {
    padding-bottom: 3.75rem
  }
}

@media (min-width: 768px) {
  .md\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:bg-gray-30 {
    --bg-opacity: 1;
    background-color: #f7f7f8;
    background-color: rgba(247, 247, 248, var(--bg-opacity))
  }

  .md\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f2f3f3;
    background-color: rgba(242, 243, 243, var(--bg-opacity))
  }

  .md\:border-blue {
    --border-opacity: 1;
    border-color: #194ef5;
    border-color: rgba(25, 78, 245, var(--border-opacity))
  }

  .md\:border-blue-wsdark {
    --border-opacity: 1;
    border-color: #183E7A;
    border-color: rgba(24, 62, 122, var(--border-opacity))
  }

  .md\:border-l-3 {
    border-left-width: 3px
  }

  .md\:border-t {
    border-top-width: 1px
  }

  .md\:block {
    display: block
  }

  .md\:inline {
    display: inline
  }

  .md\:flex {
    display: flex
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-col {
    flex-direction: column
  }

  .md\:flex-wrap {
    flex-wrap: wrap
  }

  .md\:items-center {
    align-items: center
  }

  .md\:items-stretch {
    align-items: stretch
  }

  .md\:self-end {
    align-self: flex-end
  }

  .md\:justify-end {
    justify-content: flex-end
  }

  .md\:justify-center {
    justify-content: center
  }

  .md\:justify-between {
    justify-content: space-between
  }

  .md\:justify-around {
    justify-content: space-around
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:order-1 {
    order: 1
  }

  .md\:order-2 {
    order: 2
  }

  .md\:order-first {
    order: -9999
  }

  .md\:order-last {
    order: 9999
  }

  .md\:order-none {
    order: 0
  }

  .md\:font-light {
    font-weight: 300
  }

  .md\:font-semibold {
    font-weight: 600
  }

  .md\:h-16 {
    height: 4rem
  }

  .md\:h-50p {
    height: 3.125rem
  }

  .md\:h-full {
    height: 100%
  }

  .md\:text-lg {
    font-size: 1.125rem
  }

  .md\:text-14p {
    font-size: 0.875rem
  }

  .md\:text-16p {
    font-size: 1rem
  }

  .md\:text-18p {
    font-size: 1.125rem
  }

  .md\:text-20p {
    font-size: 1.25rem
  }

  .md\:text-24p {
    font-size: 1.5rem
  }

  .md\:leading-125 {
    line-height: 1.25
  }

  .md\:-m-10p {
    margin: -0.625rem
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .md\:mx-2p {
    margin-left: 0.125rem;
    margin-right: 0.125rem
  }

  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .md\:-mx-10p {
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mr-0 {
    margin-right: 0
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:mt-10 {
    margin-top: 2.5rem
  }

  .md\:mb-10p {
    margin-bottom: 0.625rem
  }

  .md\:mt-20p {
    margin-top: 1.25rem
  }

  .md\:mb-20p {
    margin-bottom: 1.25rem
  }

  .md\:ml-20p {
    margin-left: 1.25rem
  }

  .md\:mb-30p {
    margin-bottom: 1.875rem
  }

  .md\:mt-35p {
    margin-top: 2.1875rem
  }

  .md\:mb-40p {
    margin-bottom: 2.5rem
  }

  .md\:-mt-10p {
    margin-top: -0.625rem
  }

  .md\:max-w-2xl {
    max-width: 42rem
  }

  .md\:max-w-10\/12 {
    max-width: 83.33333333333333%
  }

  .md\:max-w-300p {
    max-width: 18.75rem
  }

  .md\:max-w-340p {
    max-width: 21.25rem
  }

  .md\:opacity-50 {
    opacity: .5
  }

  .md\:overflow-x-visible {
    overflow-x: visible
  }

  .md\:p-10p {
    padding: 0.625rem
  }

  .md\:p-30p {
    padding: 1.875rem
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .md\:py-10p {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
  }

  .md\:px-10p {
    padding-left: 0.625rem;
    padding-right: 0.625rem
  }

  .md\:py-20p {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .md\:px-30p {
    padding-left: 1.875rem;
    padding-right: 1.875rem
  }

  .md\:py-60p {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem
  }

  .md\:px-120p {
    padding-left: 7.5rem;
    padding-right: 7.5rem
  }

  .md\:pb-0 {
    padding-bottom: 0
  }

  .md\:pl-10 {
    padding-left: 2.5rem
  }

  .md\:pt-12 {
    padding-top: 3rem
  }

  .md\:pr-10p {
    padding-right: 0.625rem
  }

  .md\:pl-10p {
    padding-left: 0.625rem
  }

  .md\:pt-20p {
    padding-top: 1.25rem
  }

  .md\:pb-20p {
    padding-bottom: 1.25rem
  }

  .md\:pt-30p {
    padding-top: 1.875rem
  }

  .md\:pr-30p {
    padding-right: 1.875rem
  }

  .md\:pl-30p {
    padding-left: 1.875rem
  }

  .md\:pt-40p {
    padding-top: 2.5rem
  }

  .md\:pb-50p {
    padding-bottom: 3.125rem
  }

  .md\:pt-60p {
    padding-top: 3.75rem
  }

  .md\:pb-60p {
    padding-bottom: 3.75rem
  }

  .md\:pb-300p {
    padding-bottom: 18.75rem
  }

  .md\:absolute {
    position: absolute
  }

  .md\:right-0 {
    right: 0
  }

  .md\:left-0 {
    left: 0
  }

  .md\:top-10p {
    top: 0.625rem
  }

  .md\:left-10p {
    left: 0.625rem
  }

  .md\:shadow-none {
    box-shadow: none
  }

  .md\:hover\:shadow:hover {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1)
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-center {
    text-align: center
  }

  .md\:text-right {
    text-align: right
  }

  .md\:text-black {
    --text-opacity: 1;
    color: #2c3041;
    color: rgba(44, 48, 65, var(--text-opacity))
  }

  .md\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-2\/12 {
    width: 16.666667%
  }

  .md\:w-4\/12 {
    width: 33.333333%
  }

  .md\:w-5\/12 {
    width: 41.666667%
  }

  .md\:w-6\/12 {
    width: 50%
  }

  .md\:w-7\/12 {
    width: 58.333333%
  }

  .md\:w-9\/12 {
    width: 75%
  }

  .md\:w-10\/12 {
    width: 83.33333333333333%
  }

  .md\:w-12\/12 {
    width: 100%
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-full {
    width: 100%
  }

  .md\:z-1 {
    z-index: 1
  }

  .md\:z-2 {
    z-index: 2
  }
}

@media (min-width: 860px) {
  .mlg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }
}

@media (min-width: 992px) {
  .lg\:bg-transparent {
    background-color: transparent
  }

  .lg\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:bg-gray-30 {
    --bg-opacity: 1;
    background-color: #f7f7f8;
    background-color: rgba(247, 247, 248, var(--bg-opacity))
  }

  .lg\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f2f3f3;
    background-color: rgba(242, 243, 243, var(--bg-opacity))
  }

  .lg\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-transparent {
    background-color: transparent
  }

  .lg\:border-transparent {
    border-color: transparent
  }

  .lg\:rounded {
    border-radius: 0.25rem
  }

  .lg\:rounded-full {
    border-radius: 9999px
  }

  .lg\:border-0 {
    border-width: 0
  }

  .lg\:border-b-0 {
    border-bottom-width: 0
  }

  .lg\:block {
    display: block
  }

  .lg\:inline-block {
    display: inline-block
  }

  .lg\:flex {
    display: flex
  }

  .lg\:hidden {
    display: none
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .lg\:flex-col {
    flex-direction: column
  }

  .lg\:flex-wrap {
    flex-wrap: wrap
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .lg\:items-start {
    align-items: flex-start
  }

  .lg\:items-center {
    align-items: center
  }

  .lg\:items-stretch {
    align-items: stretch
  }

  .lg\:self-auto {
    align-self: auto
  }

  .lg\:justify-start {
    justify-content: flex-start
  }

  .lg\:justify-end {
    justify-content: flex-end
  }

  .lg\:justify-center {
    justify-content: center
  }

  .lg\:justify-between {
    justify-content: space-between
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0
  }

  .lg\:order-1 {
    order: 1
  }

  .lg\:order-2 {
    order: 2
  }

  .lg\:order-first {
    order: -9999
  }

  .lg\:order-last {
    order: 9999
  }

  .lg\:font-normal {
    font-weight: 400
  }

  .lg\:font-semibold {
    font-weight: 600
  }

  .lg\:font-bold {
    font-weight: 700
  }

  .lg\:h-10 {
    height: 2.5rem
  }

  .lg\:h-16 {
    height: 4rem
  }

  .lg\:h-auto {
    height: auto
  }

  .lg\:h-30p {
    height: 1.875rem
  }

  .lg\:h-35p {
    height: 2.188rem
  }

  .lg\:h-120p {
    height: 7.5rem
  }

  .lg\:h-full {
    height: 100%
  }

  .lg\:text-sm {
    font-size: 0.875rem
  }

  .lg\:text-base {
    font-size: 1rem
  }

  .lg\:text-lg {
    font-size: 1.125rem
  }

  .lg\:text-14p {
    font-size: 0.875rem
  }

  .lg\:text-16p {
    font-size: 1rem
  }

  .lg\:text-18p {
    font-size: 1.125rem
  }

  .lg\:text-24p {
    font-size: 1.5rem
  }

  .lg\:text-36p {
    font-size: 2.25rem
  }

  .lg\:text-54p {
    font-size: 3.375rem
  }

  .lg\:leading-111 {
    line-height: 1.11
  }

  .lg\:leading-125 {
    line-height: 1.25
  }

  .lg\:leading-tight {
    line-height: 1.25
  }

  .lg\:m-auto {
    margin: auto
  }

  .lg\:-m-20p {
    margin: -1.25rem
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .lg\:my-2p {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem
  }

  .lg\:my-60p {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem
  }

  .lg\:-mx-5p {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem
  }

  .lg\:-mx-10p {
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  .lg\:-mx-20p {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .lg\:-mx-30p {
    margin-left: -1.875rem;
    margin-right: -1.875rem
  }

  .lg\:mt-0 {
    margin-top: 0
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:mt-1 {
    margin-top: 0.25rem
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem
  }

  .lg\:mt-4 {
    margin-top: 1rem
  }

  .lg\:mr-5 {
    margin-right: 1.25rem
  }

  .lg\:mt-8 {
    margin-top: 2rem
  }

  .lg\:mb-16 {
    margin-bottom: 4rem
  }

  .lg\:mr-auto {
    margin-right: auto
  }

  .lg\:mt-10p {
    margin-top: 0.625rem
  }

  .lg\:mr-10p {
    margin-right: 0.625rem
  }

  .lg\:ml-10p {
    margin-left: 0.625rem
  }

  .lg\:mt-15p {
    margin-top: 0.9375rem
  }

  .lg\:mt-20p {
    margin-top: 1.25rem
  }

  .lg\:mr-20p {
    margin-right: 1.25rem
  }

  .lg\:mb-20p {
    margin-bottom: 1.25rem
  }

  .lg\:ml-20p {
    margin-left: 1.25rem
  }

  .lg\:mt-30p {
    margin-top: 1.875rem
  }

  .lg\:mr-30p {
    margin-right: 1.875rem
  }

  .lg\:mb-30p {
    margin-bottom: 1.875rem
  }

  .lg\:mt-40p {
    margin-top: 2.5rem
  }

  .lg\:mr-40p {
    margin-right: 2.5rem
  }

  .lg\:mb-40p {
    margin-bottom: 2.5rem
  }

  .lg\:mb-50p {
    margin-bottom: 3.125rem
  }

  .lg\:mt-60p {
    margin-top: 3.75rem
  }

  .lg\:mb-60p {
    margin-bottom: 3.75rem
  }

  .lg\:-mt-5p {
    margin-top: -0.3125rem
  }

  .lg\:-mb-40p {
    margin-bottom: -2.5rem
  }

  .lg\:-mt-140p {
    margin-top: -8.75rem
  }

  .lg\:-mt-240p {
    margin-top: -15rem
  }

  .lg\:-mt-330p {
    margin-top: -20.625rem
  }

  .lg\:mb-18p {
    margin-bottom: 1.125rem
  }

  .lg\:hover\:-mb-40p:hover {
    margin-bottom: -2.5rem
  }

  .lg\:max-h-40p {
    max-height: 2.5rem
  }

  .lg\:max-w-none {
    max-width: none
  }

  .lg\:max-w-4\/12 {
    max-width: 33.33333333333333%
  }

  .lg\:max-w-8\/12 {
    max-width: 66.66666666666667%
  }

  .lg\:max-w-340p {
    max-width: 21.25rem
  }

  .lg\:max-w-440p {
    max-width: 27.5rem
  }

  .lg\:max-w-720p {
    max-width: 45rem
  }

  .lg\:max-w-800p {
    max-width: 50rem
  }

  .lg\:min-h-300p {
    min-height: 18.75rem
  }

  .lg\:min-h-600p {
    min-height: 37.7rem
  }

  .lg\:min-w-2\/12 {
    min-width: 16.66666666666667%
  }

  .lg\:hover\:opacity-50:hover {
    opacity: .5
  }

  .lg\:overflow-visible {
    overflow: visible
  }

  .lg\:overflow-x-visible {
    overflow-x: visible
  }

  .lg\:p-0 {
    padding: 0
  }

  .lg\:p-1 {
    padding: 0.25rem
  }

  .lg\:p-2 {
    padding: 0.5rem
  }

  .lg\:p-5p {
    padding: 0.3125rem
  }

  .lg\:p-20p {
    padding: 1.25rem
  }

  .lg\:p-30p {
    padding: 1.875rem
  }

  .lg\:p-40p {
    padding: 2.5rem
  }

  .lg\:p-50p {
    padding: 3.125rem
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg\:px-10p {
    padding-left: 0.625rem;
    padding-right: 0.625rem
  }

  .lg\:px-20p {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .lg\:px-30p {
    padding-left: 1.875rem;
    padding-right: 1.875rem
  }

  .lg\:py-40p {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .lg\:py-60p {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem
  }

  .lg\:px-60p {
    padding-left: 3.75rem;
    padding-right: 3.75rem
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pb-0 {
    padding-bottom: 0
  }

  .lg\:pb-4 {
    padding-bottom: 1rem
  }

  .lg\:pt-12 {
    padding-top: 3rem
  }

  .lg\:pt-16 {
    padding-top: 4rem
  }

  .lg\:pl-16 {
    padding-left: 4rem
  }

  .lg\:pb-24 {
    padding-bottom: 6rem
  }

  .lg\:pr-10p {
    padding-right: 0.625rem
  }

  .lg\:pl-10p {
    padding-left: 0.625rem
  }

  .lg\:pt-15p {
    padding-top: 0.9375rem
  }

  .lg\:pt-20p {
    padding-top: 1.25rem
  }

  .lg\:pt-30p {
    padding-top: 1.875rem
  }

  .lg\:pr-30p {
    padding-right: 1.875rem
  }

  .lg\:pb-30p {
    padding-bottom: 1.875rem
  }

  .lg\:pl-30p {
    padding-left: 1.875rem
  }

  .lg\:pt-40p {
    padding-top: 2.5rem
  }

  .lg\:pb-40p {
    padding-bottom: 2.5rem
  }

  .lg\:pl-40p {
    padding-left: 2.5rem
  }

  .lg\:pt-50p {
    padding-top: 3.125rem
  }

  .lg\:pt-60p {
    padding-top: 3.75rem
  }

  .lg\:pr-60p {
    padding-right: 3.75rem
  }

  .lg\:pb-60p {
    padding-bottom: 3.75rem
  }

  .lg\:pl-60p {
    padding-left: 3.75rem
  }

  .lg\:pr-80p {
    padding-right: 5rem
  }

  .lg\:pb-80p {
    padding-bottom: 5rem
  }

  .lg\:pr-90p {
    padding-right: 5.625rem
  }

  .lg\:pl-90p {
    padding-left: 5.625rem
  }

  .lg\:pt-100p {
    padding-top: 6.25rem
  }

  .lg\:pb-100p {
    padding-bottom: 6.25rem
  }

  .lg\:pt-120p {
    padding-top: 7.5rem
  }

  .lg\:pb-120p {
    padding-bottom: 7.5rem
  }

  .lg\:pt-160p {
    padding-top: 10rem
  }

  .lg\:pt-200p {
    padding-top: 12.5rem
  }

  .lg\:pb-200p {
    padding-bottom: 12.5rem
  }

  .lg\:pb-300p {
    padding-bottom: 18.75rem
  }

  .lg\:pb-6\/12 {
    padding-bottom: 50%
  }

  .lg\:pb-10\/12 {
    padding-bottom: 83.33333333333333%
  }

  .lg\:pb-11\/12 {
    padding-bottom: 91.66666666666667%
  }

  .group:hover .lg\:group-hover\:pb-70p {
    padding-bottom: 4.375rem
  }

  .lg\:pointer-events-none {
    pointer-events: none
  }

  .lg\:static {
    position: static
  }

  .lg\:absolute {
    position: absolute
  }

  .lg\:relative {
    position: relative
  }

  .lg\:sticky {
    position: sticky
  }

  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .lg\:top-0 {
    top: 0
  }

  .lg\:right-20p {
    right: 1.25rem
  }

  .lg\:top-40p {
    top: 2.5rem
  }

  .lg\:shadow-none {
    box-shadow: none
  }

  .lg\:shadow-card-3 {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  }

  .lg\:hover\:shadow:hover {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1)
  }

  .lg\:hover\:shadow-card:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15)
  }

  .lg\:text-left {
    text-align: left
  }

  .lg\:text-center {
    text-align: center
  }

  .lg\:text-black {
    --text-opacity: 1;
    color: #2c3041;
    color: rgba(44, 48, 65, var(--text-opacity))
  }

  .lg\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:text-orange-55 {
    --text-opacity: 1;
    color: #F89400;
    color: rgba(248, 148, 0, var(--text-opacity))
  }

  .lg\:text-blue {
    --text-opacity: 1;
    color: #194ef5;
    color: rgba(25, 78, 245, var(--text-opacity))
  }

  .lg\:uppercase {
    text-transform: uppercase
  }

  .lg\:w-auto {
    width: auto
  }

  .lg\:w-30p {
    width: 1.875rem
  }

  .lg\:w-60p {
    width: 3.75rem
  }

  .lg\:w-120p {
    width: 7.5rem
  }

  .lg\:w-130p {
    width: 8.125rem
  }

  .lg\:w-160p {
    width: 10rem
  }

  .lg\:w-2\/12 {
    width: 16.666667%
  }

  .lg\:w-3\/12 {
    width: 25%
  }

  .lg\:w-4\/12 {
    width: 33.333333%
  }

  .lg\:w-5\/12 {
    width: 41.666667%
  }

  .lg\:w-6\/12 {
    width: 50%
  }

  .lg\:w-7\/12 {
    width: 58.333333%
  }

  .lg\:w-8\/12 {
    width: 66.666667%
  }

  .lg\:w-9\/12 {
    width: 75%
  }

  .lg\:w-10\/12 {
    width: 83.33333333333333%
  }

  .lg\:w-11\/12 {
    width: 91.66666666666667%
  }

  .lg\:w-1\/2 {
    width: 50%
  }

  .lg\:w-1\/5 {
    width: 20%
  }

  .lg\:w-full {
    width: 100%
  }

  .lg\:w-1\/8 {
    width: 12.5%
  }

  .lg\:w-340p {
    width: 21.25rem
  }
}

@media (min-width: 1100px) {
  .xl\:rounded {
    border-radius: 0.25rem
  }

  .xl\:block {
    display: block
  }

  .xl\:flex {
    display: flex
  }

  .xl\:hidden {
    display: none
  }

  .xl\:justify-start {
    justify-content: flex-start
  }

  .xl\:order-none {
    order: 0
  }

  .xl\:h-140p {
    height: 8.75rem
  }

  .xl\:text-sm {
    font-size: 0.875rem
  }

  .xl\:leading-143 {
    line-height: 1.43
  }

  .xl\:-m-20p {
    margin: -1.25rem
  }

  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .xl\:-mx-17p {
    margin-left: -1.09rem;
    margin-right: -1.09rem
  }

  .xl\:-mx-30p {
    margin-left: -1.875rem;
    margin-right: -1.875rem
  }

  .xl\:-mx-60p {
    margin-left: -3.75rem;
    margin-right: -3.75rem
  }

  .xl\:mr-0 {
    margin-right: 0
  }

  .xl\:mb-0 {
    margin-bottom: 0
  }

  .xl\:mb-2 {
    margin-bottom: 0.5rem
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem
  }

  .xl\:mb-16 {
    margin-bottom: 4rem
  }

  .xl\:mt-30p {
    margin-top: 1.875rem
  }

  .xl\:mr-40p {
    margin-right: 2.5rem
  }

  .xl\:ml-40p {
    margin-left: 2.5rem
  }

  .xl\:mb-60p {
    margin-bottom: 3.75rem
  }

  .xl\:-mb-60p {
    margin-bottom: -3.75rem
  }

  .xl\:max-w-1060p {
    max-width: 66.25rem
  }

  .xl\:p-20p {
    padding: 1.25rem
  }

  .xl\:p-30p {
    padding: 1.875rem
  }

  .xl\:p-40p {
    padding: 2.5rem
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .xl\:px-17p {
    padding-left: 1.09rem;
    padding-right: 1.09rem
  }

  .xl\:px-20p {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .xl\:px-30p {
    padding-left: 1.875rem;
    padding-right: 1.875rem
  }

  .xl\:py-40p {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .xl\:px-60p {
    padding-left: 3.75rem;
    padding-right: 3.75rem
  }

  .xl\:pl-0 {
    padding-left: 0
  }

  .xl\:pt-30p {
    padding-top: 1.875rem
  }

  .xl\:pr-30p {
    padding-right: 1.875rem
  }

  .xl\:pl-30p {
    padding-left: 1.875rem
  }

  .xl\:pt-40p {
    padding-top: 2.5rem
  }

  .xl\:pr-40p {
    padding-right: 2.5rem
  }

  .xl\:pr-50p {
    padding-right: 3.125rem
  }

  .xl\:pl-50p {
    padding-left: 3.125rem
  }

  .xl\:pt-60p {
    padding-top: 3.75rem
  }

  .xl\:pr-60p {
    padding-right: 3.75rem
  }

  .xl\:pb-60p {
    padding-bottom: 3.75rem
  }

  .xl\:pl-60p {
    padding-left: 3.75rem
  }

  .xl\:pl-130p {
    padding-left: 8.125rem
  }

  .xl\:pb-8\/12 {
    padding-bottom: 66.66666666666667%
  }

  .xl\:shadow {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1)
  }

  .xl\:text-center {
    text-align: center
  }

  .xl\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:normal-case {
    text-transform: none
  }

  .xl\:w-80p {
    width: 5rem
  }

  .xl\:w-130p {
    width: 8.125rem
  }

  .xl\:w-200p {
    width: 12.5rem
  }

  .xl\:w-390p {
    width: 24.38rem
  }

  .xl\:w-3\/12 {
    width: 25%
  }

  .xl\:w-4\/12 {
    width: 33.333333%
  }

  .xl\:w-5\/12 {
    width: 41.666667%
  }

  .xl\:w-6\/12 {
    width: 50%
  }

  .xl\:w-7\/12 {
    width: 58.333333%
  }

  .xl\:w-8\/12 {
    width: 66.666667%
  }

  .xl\:w-9\/12 {
    width: 75%
  }

  .xl\:w-10\/12 {
    width: 83.33333333333333%
  }

  .xl\:w-11\/12 {
    width: 91.66666666666667%
  }

  .xl\:w-full {
    width: 100%
  }

  .xl\:w-3\/8 {
    width: 37.5%
  }

  .xl\:w-4\/8 {
    width: 50%
  }
}
