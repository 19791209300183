.ribbon {
  height: rem(20px);
  background-image: url('../../assets/svg/ribbon.svg');
  background-repeat: repeat-x;
  background-position: center;
  background-size: auto 1.25rem;

  &-broken {
    height: rem(70px);
    background-image: url('../../assets/svg/ribbon-broken.svg');
    background-size: auto rem(70px);
  }
}

.wordminds-site {
  .ribbon {
    background-image: url('../../assets/svg/ribbon-wordminds.svg');
  }
}
