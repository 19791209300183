.tooltip {
  display: block;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  background-color: #959ca0;
  border-radius: 50%;
}
