.container {
  &-wide {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: rem(1080px);
  }

  &-loose {
    @media (min-width: 1440px) {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      max-width: rem(1440px);
    }
  }

  &-full {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: rem(1920px);
  }

  &--industries {
    max-width: rem(1440px);
  }
}
