@media (min-width: 1380px) {
  .heading-xxl-large {
    font-size: 3rem;
  }
}

@media (min-width: 1680px) {
  .heading-xxl-large {
    font-size: 3.375rem;
  }
}
