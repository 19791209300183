@font-face {
  font-family: 'Fieldwork';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/hinted-Fieldwork-HumRegular.woff2') format('woff2'),
    url('../../assets/fonts/hinted-Fieldwork-HumRegular.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Fieldwork';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/hinted-Fieldwork-HumBold.woff2') format('woff2'),
    url('../../assets/fonts/hinted-Fieldwork-HumBold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Fieldwork';
  font-weight: 600;
  font-style: normal;
  src: url('../../assets/fonts/hinted-Fieldwork-Hum-DemiBold.woff2') format('woff2'),
    url('../../assets/fonts/hinted-Fieldwork-Hum-DemiBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Fieldwork';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/hinted-Fieldwork-Hum-Light.woff2') format('woff2'),
    url('../../assets/fonts/hinted-Fieldwork-Hum-Light.woff') format('woff');
  font-display: swap;
}

/* wordminds-site */

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/avenir/avenir-heavy.woff2') format('woff2'),
    url('../../assets/fonts/avenir/avenir-heavy.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Avenir';
  font-weight: 600;
  font-style: normal;
  src: url('../../assets/fonts/avenir/avenir-medium.woff2') format('woff2'),
    url('../../assets/fonts/avenir/avenir-medium.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/avenir/avenir-book.woff2') format('woff2'),
    url('../../assets/fonts/avenir/avenir-book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/avenir/avenir-light.woff2') format('woff2'),
    url('../../assets/fonts/avenir/avenir-light.woff') format('woff');
  font-display: swap;
}
