/* purgecss start ignore */
.tab-controls {
  &--join-team {
    button {
      position: relative;

      &::after {
        position: absolute;
        bottom: rem(-10px);
        left: 50%;
        display: none;
        width: 0;
        height: 0;
        content: '';
        border-width: 10px 10px 0;
        border-style: solid;
        border-color: theme('colors.orange.default') transparent transparent transparent;
        transform: translate3d(-50%, 0, 0);
      }
      /* stylelint-disable */
      &.is-active {
        color: theme('colors.white.default');

        &::after {
          display: block;
        }
        &::before {
          background-color: theme('colors.orange.default');
        }
      }
      /* stylelint-enable */
    }

    .left::before {
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      content: '';
      background-color: theme('colors.gray.30');
    }

    .right::before {
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      content: '';
      background-color: theme('colors.gray.30');
    }

    .right {
      @media screen and (min-width: 1440px) {
        padding-left: 140px;
      }

      /* stylelint-disable */
      &::after {
        @media screen and (min-width: 1440px) {
          left: 65%;
        }
      }

      /* stylelint-enable */
    }

    .left {
      @media screen and (min-width: 1440px) {
        padding-right: 140px;
      }
      /* stylelint-disable */
      &::after {
        @media screen and (min-width: 1440px) {
          left: 35%;
        }
      }
      /* stylelint-enable */
    }
  }

  &--index {
    & > button {
      border-bottom: 2px solid transparent;
    }

    .is-active {
      color: theme('colors.black.default');
      border-bottom: 2px solid theme('colors.orange.default');
    }
  }

  &--services-we-choose {
    & > button {
      position: relative;
      padding-left: 33px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        padding: 2px;
        display: block;
        width: 21px;
        height: 21px;
        content: '';
        background-color: theme('colors.disable.secondary');
        background-clip: content-box;
        border: 1px solid transparent;
        border-radius: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }

    .is-active {
      font-weight: 600;
      border-bottom-color: transparent;

      &::before {
        background-color: theme('colors.orange.default');
        border-color: theme('colors.orange.default');
        border-top-color: transparent;
        transform: translate3d(0, -50%, 0) rotate(-45deg);
      }
    }
  }
}

.js-item {
  &--join-team {
    display: none;

    &.is-active {
      display: block;
    }
  }

  &--tabber {
    &.is-active {
      @media (min-width: theme('screens.lg')) {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s, visibility 0.5s;
      }
    }
  }
}

.tab {
  .swiper-slide {
    /* stylelint-disable */
    width: auto !important;
    /* stylelint-enable */
    border-bottom: 2px solid transparent;

    &-thumb-active {
      color: theme('colors.black.default');
      border-bottom: 2px solid theme('colors.orange.default');
    }
  }
}

.wordminds-site {
  .tab .swiper-slide-thumb-active {
    border-color: theme('colors.green.wslight');
  }

  .tab-controls--join-team button.is-active::before {
    background-color: theme('colors.green.wslight');
  }

  .tab-controls--join-team button::after {
    border-color: theme('colors.green.wslight') transparent transparent transparent;
  }

  .tab-controls--join-team .right::before {
    background-color: theme('colors.blue.wslight');
  }
}

/* purgecss end ignore */
