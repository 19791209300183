.no-scroll {
  overflow: hidden;
  height: 100%;
}

.is-hidden {
  display: none;
}

.is-scroll {
  overflow-y: scroll;
  height: 100vh;
}

.transition-cst,
svg {
  transition: 0.35s;
}

.transition-cst-transform {
  transition: transform 0.35s;
}

.lazyload {
  opacity: 0;
  transition: 0.3s;
}

.lazyloaded {
  opacity: 1;
}

.w-transition {
  transition: 0.5s ease;
}

.img-centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
