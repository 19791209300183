.langs {
  @media (min-width: theme('screens.lg')) {
    position: relative;
  }

  &:hover {
    @media (min-width: theme('screens.lg')) {
      & > .langs__list {
        z-index: 50;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.langs__list,
.langs-list {
  position: fixed;
  z-index: 50;
  right: 0;
  bottom: 0;
  left: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &::before {
    position: absolute;
    top: -6px;
    left: 50%;
    margin-bottom: -3px;
    display: none;
    width: 0;
    height: 0;
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    transform: translate3d(-50%, 0, 0);

    @media (min-width: theme('screens.lg')) {
      display: block;
    }
  }

  @media (min-width: theme('screens.lg')) {
    position: absolute;
    z-index: -1;
    top: 100%;
    right: auto;
    bottom: auto;
    left: 50%;
    visibility: hidden;
    opacity: 0;
    text-align: left;
    border-radius: 0.25rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    transform: translate3d(-50%, 0, 0);

    a {
      color: theme('colors.black.default');
      transition: 0.35s ease color;
    }

    .is-active a,
    a:hover,
    a:focus {
      color: theme('colors.orange.default');
    }
  }
}
