/* stylelint-disable */

.ss-main {
  padding-right: 1rem;
  padding-left: 0;
  min-height: 2.5rem;
  font-size: 0.875rem;
  line-height: 2.375rem;
  color: theme('colors.black.50');
  background-color: theme('colors.gray.50');
  border: 1px solid transparent;
  border-radius: 0.125rem;

  &.is-active {
    color: theme('colors.black.default');
  }

  .ss-search {
    input {
      background-image: url('../../assets/svg/search-black.svg');
      background-repeat: no-repeat;
      background-position: right 16px center;
      background-size: 16px;
    }
  }

  .ss-single-selected {
    padding: 0;
    height: inherit;
    background-color: transparent;
    border: none;

    .placeholder {
      padding-left: 1rem;
      font-size: inherit;
      line-height: inherit;

      .ss-disabled {
        color: inherit;
      }
    }

    .ss-arrow {
      margin: 0;
    }
  }

  .ss-multi-selected {
    padding: 0;
    min-height: 2.5rem;
    background-color: transparent;
    border: none;

    .ss-values {
      font-size: inherit;
      line-height: inherit;

      .ss-disabled {
        margin: 0;
        padding: 0 0 0 1rem;
        color: inherit;
      }

      .ss-value {
        margin: 1px 2px;
        padding-top: 0;
        padding-right: 0;
        padding-left: rem(10px);
        padding-bottom: 0;
        display: inline-flex;
        align-items: center;
        font-size: rem(14px);
        background-color: theme('colors.blue.default');
        animation: none;
      }

      .ss-value-delete {
        margin-left: 0;
        padding: 0 rem(10px);
      }
    }

    .ss-add {
      margin: 0;
      margin-top: 16px;
      margin-right: 3px;
      flex: unset;
    }
  }

  .ss-content {
    top: calc(100% + 6px);
    left: 0;
    margin: 0;
    border: none;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .ss-search {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;

    input {
      padding-right: 1rem;
      padding-left: 1rem;
      height: 2.5rem;
      color: theme('colors.black.default');
      background-color: theme('colors.gray.50');

      &::-webkit-input-placeholder {
        color: theme('colors.black.50');
      }

      &::-moz-placeholder {
        color: theme('colors.black.50');
      }

      &:-ms-input-placeholder {
        color: theme('colors.black.50');
      }
    }
  }

  .ss-list {
    display: flex;
    max-height: 280px;
    flex-direction: column;

    .ss-option {
      padding: 10px 5px 10px 1rem;
      font-size: rem(14px);
      line-height: 1.43;
      color: theme('colors.black.default');

      &:not(.ss-option-selected):hover {
        color: theme('colors.black.default');
        background-color: #f7f7f8;
      }
    }
  }

  .ss-list .ss-optgroup {
    padding-top: 0.5rem;

    .ss-optgroup-label {
      padding-top: 10px;
      padding-left: 16px;
      padding-bottom: 10px;
      line-height: 1.43;
      color: #2c3041;
    }

    .ss-option {
      padding: 10px 0;
      padding-left: 32px;

      /* background-color: #f7f7f8;
			&:hover {
				background-color: #f2f3f3;
			} */
    }
  }

  &.js-popular,
  &.popular-languages {
    .ss-optgroup {
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .ss-option {
        padding-left: 1rem !important;
      }

      .ss-optgroup-label {
        /* margin-top: rem(10px); */

        /* padding-left: 1rem; */
        font-size: rem(12px);
        line-height: rem(20px);
        font-weight: 700;
        text-transform: uppercase;
        color: theme('colors.black.default');
      }
    }
  }
}

select.slim-select {
  overflow: hidden;

  * {
    opacity: 0;
  }
}

.wordminds-site {
  .ss-main {
    background-color: theme('colors.blue.wslight');
  }

  .ss-multi-selected {
    .ss-values {
      .ss-value {
        background-color: theme('colors.green.wslight');
      }
    }
  }
}

/* stylelint-disable end */
