/* stylelint-disable */
/**
 * Media query breakpoints mixins
 *
 * Usage:
 * div { width: 60px;
 *  @include bp-min($small) { width: 80px; }
 * }
 */
@mixin bp-min($point: 0) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin bp-max($point: 0) {
  @media (max-width: $point - 0.0625em) {
    @content;
  }
}

@mixin bp-min-max($min-point: 0, $max-point: 0) {
  @media (min-width: $min-point) and (max-width: $max-point - 0.0625em) {
    @content;
  }
}

@mixin font-size($size) {
  font-size: map-get($font-sizes, $size);

  @screen lg {
    font-size: map-get($font-sizes-large, $size);
  }
}

@mixin hocus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
  position: $pos;
  display: $display;
  content: $content;
}

@mixin triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);

  width: 0;
  height: 0;

  @if $round {
    border-radius: 3px;
  }

  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}
