@responsive {
  .ratio-1 {
    padding-bottom: 100%;
  }

  .ratio-6-5 {
    padding-bottom: 83.33%;
  }

  .ratio-4-3 {
    padding-bottom: 75%;
  }

  .ratio-3-2 {
    padding-bottom: 66.66%;
  }

  .ratio-8-5 {
    padding-bottom: 62.5%;
  }

  .ratio-16-9 {
    padding-bottom: 56.25%;
  }

  .ratio-9-16 {
    padding-bottom: 177.77777777777777%;
  }

  .ratio-3-4 {
    padding-bottom: 133.33333333333334%;
  }

  .ratio-2-3 {
    padding-bottom: 150%;
  }

  .ratio-5-8 {
    padding-bottom: 160%;
  }

  .ratio-5-6 {
    padding-bottom: 120%;
  }
}
