.banner-hero {
  overflow: hidden;
  min-height: 200vw;
  transition: 0.35 ease-in;

  &__heading {
    @media screen and (min-width: 1024px) and (max-width: 1680px) {
      font-size: 42px;
    }
  }

  &__desc {
    @media screen and (min-width: 1024px) and (max-width: 1680px) {
      font-size: 18px;
    }
  }

  &__image {
    transition: 0.35 ease-in opacity;

    & lazyload {
      opacity: 0;
    }

    & lazyloaded {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 400px) {
    min-height: (100vw * 6/4);
  }

  @media only screen and (min-width: 560px) {
    min-height: 100vw;
  }

  @media only screen and (min-width: 680px) {
    min-height: calc((100vw * 3/4) - 40px);
  }

  @media only screen and (min-width: 764px) {
    min-height: calc((100vw * 3/5) - 40px);
  }

  @media only screen and (min-width: 1024px) {
    min-height: calc((100vw * 3/6) - 40px);
  }

  @media only screen and (min-width: 1320px) {
    min-height: calc((100vw * 1/3) - 40px);
  }

  @media only screen and (min-width: 1920px) {
    min-height: calc((1920px * 1/3) - 40px);
  }
}

