/*! purgecss start ignore */

.sticky-nav {
  transition: 0.35s ease-in-out;

  &.is-sticky {
    position: fixed;
    z-index: 50;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;

    .advanced {
      display: none;
    }

    .sign-link,
    .langs,
    .langs__btn {
      line-height: 24px;
    }

    .navigation__item {
      &:nth-last-child(2) {
        margin-left: auto;
      }

      &:nth-last-child(3) {
        margin-left: unset;
      }
    }
  }
}

/*! purgecss end ignore */
