/* stylelint-disable */

.crumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;

  a {
    position: relative;
    line-height: 1.43;
    font-weight: bold;

    &::after {
      position: absolute;
      top: 50%;
      right: -17px;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      background-image: url('../../../assets/svg/arrow-icon-w.svg');
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(-90deg) translateX(30%);
    }
  }

  > * + * {
    margin-left: 24px;
  }

  &--dark {
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;

    a {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      transition: 0.35s ease all;
      opacity: 0.5;

      &:hover,
      &:focus {
        color: theme('colors.orange.default');
        opacity: 1;
      }

      &::after {
        background-image: url('../../../assets/svg/arrow-icon.svg');
      }
    }

    > * + * {
      margin-left: 20px;
    }
  }

  &--dark + * {
    padding-top: 0;
  }

  &-fly-container {
    display: none;
    padding-top: 36px;
    padding-bottom: 36px;
    background-color: transparent;
    height: 90px;
    overflow: hidden;
  }

  @screen sm {
    &-fly-container {
      display: block;
    }

    &-fly-container + * {
      margin-top: -90px !important;
      padding-top: 90px !important;
    }
  }
}

.wordminds-site {
  .crumbs,
  .crumbs--dark {
    a:hover {
      color: theme('colors.green.wslight');
    }
  }
}
