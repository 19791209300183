/* stylelint-disable */
.ff {
  &-form-errors,
  &-form-success {
    margin-bottom: 1.25em;
  }

  &-form-success {
    color: theme('colors.green.default');
  }

  &-form-errors {
    color: theme('colors.red.default');
  }

  &-has-errors {
    border-color: theme('colors.red.default');

    &:hover {
      border-color: theme('colors.red.default') !important;
    }
  }

  &-errors {
    margin-top: 0.5em;
    font-size: 1em;
    color: theme('colors.red.default');

    &.mb-0 {
      margin-bottom: 0;
    }
  }
}

input[type='checkbox'].ff-has-errors + label:not(.checkbox-inversed)::before {
  border: 2px solid theme('colors.red.default');
}

.inversed-form {
  .ff-form-errors {
    color: theme('colors.orange.50');
  }

  .ff-errors {
    color: theme('colors.orange.50');
  }
}
