.swiper-industries {
  .swiper-slide {
    @screen sm {
      width: 50%;
    }

    @screen lg {
      width: (100% / 12 * 4);
    }

    @screen xl {
      max-width: 360px;
    }
  }
}
