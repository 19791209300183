/* stylelint-disable */
@responsive {
  @variants hover, group-hover, focus {
    .scale-1.2 {
      transform: scale(1.2);
    }

    .scale-1-3 {
      transform: scale(1.3);
    }

    .scale-1.5 {
      transform: scale(1.5);
    }

    .scale-2x {
      transform: scale(2);
    }

    .rotate-90 {
      transform: rotate(90deg);
    }

    .rotate-180 {
      transform: rotate(180deg);
    }

    .ebook-rotate {
      transform: rotate(-4deg);
    }

    .remove-transform {
      transform: unset;
    }
  }
}
