/*! purgecss start ignore */

.search-form {
  position: relative;
  display: none;
  min-width: 1rem;

  &:hover,
  &:focus,
  &.active {
    opacity: 1;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 1.5rem;
    padding-left: 0.5rem;
    min-width: rem(0);
    max-width: rem(0);
    opacity: 0;
    line-height: rem(40px);
    color: theme('colors.black.default');
    border-width: 0;
    border-radius: rem(4px);
    transition: 0.35s ease;
  }

  &__btn {
    position: absolute;
    right: 0;
    padding-bottom: 2px;
    display: flex;
    min-width: 1rem;
    height: rem(40px);
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 0 4px 4px 0;
    transition: 0.35s ease min-width;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  &__icon {
    width: 1rem;
    height: auto;
  }

  &.is-active {
    color: theme('colors.black.default');

    .search-form__input {
      z-index: 1;
      min-width: rem(260px);
      opacity: 1;
      transition: 0.35s ease min-width;
    }

    .search-form__btn {
      z-index: 2;
      min-width: rem(40px);
      opacity: 1;

      &:hover {
        opacity: 1;
        color: theme('colors.orange.default');
      }
    }
  }

  @screen lg {
    margin-top: -3px;
    display: block;
    height: rem(40px);
    color: theme('colors.white.default');
  }
}

.is-inverse {
  .search-form {
    display: none;

    @media (min-width: 1440px) {
      margin-right: 1rem;
      margin-left: 1rem;
      display: block;
      opacity: 0.25;
      color: theme('colors.black.default');
    }

    &.is-active {
      margin-right: 0;
      margin-left: 0;
      opacity: 1;

      .search-form__input {
        background-color: theme('colors.gray.50');
        border-radius: 0;
      }
    }
  }
}

.wordminds-site {
  .search-form.is-active .search-form__btn:hover {
    color: theme('colors.green.wslight');
  }
}

/*! purgecss end ignore */
