/*! purgecss start ignore */

.navigation__inner {
  visibility: hidden;
  opacity: 0;
  transition: transform 0.35s ease, visibility 0.25s ease, opacity 0.2s ease;
  transform: translate3d(100vw, 0, 0);

  &.is-open {
    visibility: visible;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0);

    @media (min-width: theme('screens.msm')) {
      transform: translate3d(40vw, 0, 0);
    }

    @media (min-width: theme('screens.md')) {
      transform: translate3d(50vw, 0, 0);
    }

    @media (min-width: theme('screens.lg')) {
      transform: translate3d(0, 0, 0);
    }
  }

  @media (min-width: theme('screens.msm')) {
    max-width: 60vw;
  }

  @media (min-width: theme('screens.md')) {
    max-width: 50vw;
  }

  @media (min-width: theme('screens.lg')) {
    visibility: unset;
    max-width: unset;
    opacity: unset;
    transform: unset;
  }
}

@screen lg {
  .navigation__item + .navigation__item {
    margin-left: 20px;
  }
}

.navigation__item {
  flex-shrink: 0;

  @screen lg {
    &:nth-last-child(3) {
      margin-left: auto;
      padding-left: rem(20px);
    }
  }

  &.has-dropdown {
    & > a {
      position: relative;
      display: flex;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 15px;
        height: 9px;
        content: '';
        background-image: url('../../../assets/svg/nav-chevron.svg');
        background-repeat: no-repeat;
        background-size: 15px 9px;
        transform: translate3d(0, -50%, 0) rotate(-90deg);

        @media (min-width: theme('screens.lg')) {
          background-position: right center;
          background-size: 10px 5px;
          transform: translate3d(0, -50%, 0) rotate(0deg);
        }
      }

      @media (min-width: theme('screens.lg')) {
        padding-right: 0.75rem;
      }

      @media (min-width: theme('screens.xl')) {
        padding-right: 1rem;
      }
    }

    @media (min-width: theme('screens.lg')) {
      position: relative;

      &::before {
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        content: '';
      }
    }

    @media (min-width: theme('screens.lg')) {
      position: relative;

      &:hover {
        & > .dropdown {
          z-index: 50000;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.navigation__link {
  line-height: 1.5rem;

  &.back-track {
    position: relative;
    padding-left: 25px;
    display: block;
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 600;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 15px;
      height: 9px;
      content: '';
      background-image: url('../../../assets/svg/nav-chevron.svg');
      background-repeat: no-repeat;
      background-size: 15px 9px;
      transform: translate3d(0, -50%, 0) rotate(90deg);
    }
  }

  @media (min-width: theme('screens.lg')) {
    transition: 0.35s ease opacity;

    &:not(.search-link):hover,
    &:not(.search-link):focus,
    &:not(.search-link).active {
      opacity: 0.5;
    }
  }
}

.dropdown {
  position: absolute;
  z-index: 35;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  width: 100%;
  background-color: theme('colors.blue.default');
  transform: translate3d(100vw, 0, 0);

  &::before {
    position: absolute;
    top: -6px;
    left: 50%;
    margin-bottom: -3px;
    display: none;
    width: 0;
    height: 0;
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    transform: translate3d(-50%, 0, 0);

    @media (min-width: theme('screens.lg')) {
      display: block;
    }
  }

  &__item {
    margin-top: 1.25rem;

    @media (min-width: theme('screens.lg')) {
      margin-top: 1.25rem;
      font-size: 0.875rem;
      color: theme('colors.black.default');
    }
  }

  &.is-active {
    transform: translate3d(0, 0, 0);

    @media (min-width: theme('screens.lg')) {
      transform: translate3d(-50%, 0, 0);
    }
  }

  @media (min-width: theme('screens.lg')) {
    z-index: -1;
    top: 150%;
    bottom: unset;
    left: 50%;
    padding: 0 1.25rem 1.25rem;
    visibility: hidden;
    overflow: unset;
    min-width: 250px;
    opacity: 0;
    background-color: theme('colors.white.default');
    border-radius: 0.25rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    transition: 0.35s ease-in-out;
    transform: translate3d(-50%, 0, 0);

    a:hover,
    a:focus,
    a.active {
      color: theme('colors.orange.default');
    }
  }
}

.nav-toggler {
  background-image: url(../../../assets/svg/burger.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 20px;

  &.cross {
    background-image: url(../../../assets/svg/cross.svg);
  }
}

.navigation.is-inverse {
  .nav-toggler {
    background-image: url(../../../assets/svg/burger-dark.svg);

    &.cross {
      background-image: url(../../../assets/svg/cross.svg);
    }
  }

  @screen lg {
    .navigation__link {
      @media (min-width: theme('screens.lg')) {
        color: theme('colors.black.default');
      }
    }

    .search-form {
      opacity: 1;
    }

    .navigation__item.has-dropdown {
      & > a {
        &::after {
          background-image: url('../../../assets/svg/nav-chevron-dark.svg');
        }
      }
    }
  }
}

.navigation.is-tiny {
  padding-top: 12px;
  padding-bottom: 12px;
  transition: 0.35s ease-in-out padding;

  @screen lg {
    .search-widget {
      display: none;
    }

    .navigation__item.has-dropdown {
      & > a {
        position: relative;
        display: flex;
      }
    }

    .langs {
      @media (min-width: theme('screens.lg')) {
        color: theme('colors.black.default');
      }
    }

    .btn-large {
      padding-right: 13px;
      padding-left: 13px;
      height: 36px;
      border-radius: 2px;
    }
  }
}

.wordminds-site {
  .navigation__inner,
  .dropdown {
    color: #fff;
    background-color: theme('colors.black.default');
  }

  @screen lg {
    .navigation {
      a {
        font-weight: 600;
      }

      a:hover,
      a:focus,
      a.active {
        opacity: 0.5;
      }

      .btn-large {
        padding-right: 16px;
        padding-left: 16px;
        border-radius: 0 0 2px 2px;

        &:hover {
          opacity: 1;
        }
      }

      .dropdown {
        background-color: #fff;

        a:hover,
        a:focus,
        a.active {
          opacity: 1;
          color: theme('colors.green.wslight');
        }
      }
    }

    .navigation__inner {
      background-color: transparent;
    }

    .navigation.is-inverse {
      margin-top: 0;

      .btn-large {
        color: #fff;
        border-radius: 0 0 2px 2px;

        &:hover {
          opacity: 1;
          color: #fff;
        }
      }
    }
  }

  .navigation.is-inverse {
    @screen lg {
      .navigation__link {
        @media (min-width: theme('screens.lg')) {
          color: theme('colors.black.default');
        }
      }

      .search-form {
        opacity: 1;
      }
    }
  }

  .navigation.is-tiny {
    @screen lg {
      .btn-large {
        border-radius: 1.5rem;
      }
    }
  }
}

/*! purgecss end ignore */
