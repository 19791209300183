/* stylelint-disable */

.wordminds-site {
  .footer {
    .rounded {
      border-radius: 25px;
    }

    .rounded-r {
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
    }

    .bg-orange {
      background-color: theme('colors.green.wslight');
    }

    .bg-orange:hover {
      background-color: theme('colors.green.wsnormal');
    }
  }
}
