/* stylelint-disable */

.swiper1 {
  .swiper-slide {
    position: relative;
    padding-left: 33px;
    height: auto;
    text-align: left;
    background-color: transparent;
    border: none;

    &.selected {
      position: relative;
      width: 100%;
      font-weight: 600;

      svg {
        animation: countdown 10000ms linear forwards;
      }

      .icon::before {
        background-color: #f7941d;
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    width: 22px;
    height: 22px;
    transform: translate3d(0, -50%, 0);

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 2px;
      display: block;
      box-sizing: content-box;
      width: 12px;
      height: 12px;
      content: '';
      background-color: #b0b5b8;
      background-clip: content-box;
      border: 1px solid transparent;
      border-radius: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0);
    }

    & > svg {
      position: absolute;
      top: 50%;
      left: 0;
      width: 22px;
      height: 22px;
      transform: translate3d(0, -50%, 0) rotateZ(-90deg);
      stroke-dasharray: 62.83185;
      stroke-dashoffset: 62.83185;
      stroke-linecap: round;
      stroke-width: 2px;
      stroke: #f7941d;
    }
  }

  @keyframes countdown {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.swiper2 {
  .swiper-wrapper {
    display: block;
    box-sizing: border-box;

    @media (min-width: theme('screens.lg')) {
      display: flex;
    }
  }
}

.wordminds-site {
  .swiper1 {
    .swiper-slide {
      &.selected {
        .icon::before {
          background-color: theme('colors.green.wslight');
        }
      }

      .icon {
        & > svg {
          stroke: theme('colors.green.wslight');
        }
      }
    }
  }
}
