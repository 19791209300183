.video-popup {
  z-index: 50;
  top: 50%;
  left: 50%;
  width: 90vw;
  height: auto;
  transform: translate(-50%, -50%);

  @media (min-width: theme('screens.msm')) {
    width: 65vw;
  }

  @media (min-width: theme('screens.md')) {
    width: 80vw;
  }

  @media (min-width: theme('screens.lg')) {
    width: 56.25vw;
  }

  .close {
    position: absolute;
    top: rem(-30px);
    right: 0;

    @media (min-width: theme('screens.lg')) {
      top: 0;
      right: rem(-30px);
    }
  }
}
/* stylelint-disable */
.plyr {
  border-radius: 4px !important;
}

button.plyr__control--overlaid {
  background: theme('colors.white.default');
  color: theme('colors.blue.default');
  transition: unset;
  &:hover,
  &:focus {
    background: theme('colors.orange.default') !important;
    color: theme('colors.white.default') !important;
  }
}
