.blockquote {
  @apply mt-40p pt-10p pb-10p pl-20p -ml-20p;

  border-left: 3px solid theme('colors.blue.default');

  @screen lg {
    @apply ml-0 pl-30p;
  }

  &__text {
    @apply font-light text-18p;
  }

  &__author {
    @apply font-bold text-16p mt-20p;

    @screen lg {
      @apply mt-40p;
    }
  }

  &__author-position {
    opacity: 0.5;
  }

  &.is-centered {
    @screen lg {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
