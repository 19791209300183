.join-team-form {
  fieldset {
    border-bottom: 1px solid #ccc;

    @media (min-width: theme('screens.lg')) {
      border-bottom: none;
    }
  }
}

.js-accordeon-btn {
  &.is-active {
    & > .ml-auto {
      transform: rotate(-180deg);
    }
  }
}

.js-accordeon-content {
  overflow: hidden;
  max-height: 0;
  transition: 0.35s cubic-bezier(0, 1, 0, 1);

  @media (min-width: theme('screens.lg')) {
    overflow: unset;
    max-height: unset;
  }

  &.is-active {
    padding-bottom: 30px;
    overflow: visible;
    max-height: 1500px;
  }
}

#accordeon-contacts {
  .js-accordeon-content {
    overflow: hidden;
    max-height: 0;
    transition: 0.2s;

    @media (min-width: theme('screens.lg')) {
      overflow: unset;
      max-height: unset;
    }

    &.is-active {
      padding-bottom: 20px;
      overflow: visible;
      max-height: 500px;
    }
  }
}
