img,
embed,
object,
video {
  max-width: 100%;
}

img.full,
object.full,
.main img,
.main object {
  width: 100%;
}

button:focus {
  outline: none;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
  position: absolute;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  border: 0;
  clip-path: inset(100%);
}

img,
picture {
  pointer-events: none;
}
