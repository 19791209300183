/* stylelint-disable */
.w-overlay {
  &:before {
    @include pseudo();
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: linear-gradient(to top, #000000 0, rgba(0, 0, 0, 0));
    transition: 0.25s ease;
  }

  &-black {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: rgba(0, 0, 0, 0.35);
      }
    }
  }

  &-blue {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: rgba(25, 79, 245, 0.9);
        background-image: none;
      }
    }
  }

  &-lightblue {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: rgba(25, 79, 245, 0.5);
        background-image: none;
      }
    }
  }

  &-blue-active {
    &:before {
      background-image: none;
      background-color: rgba(25, 79, 245, 0.9);
    }
  }

  &-dark-active {
    &:before {
      background-image: none;
      background-color: rgba(44, 48, 65, 0.65);
    }
  }

  &-red {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.red.5');
        background-image: none;
      }
    }
  }

  &-green {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.green.5');
        background-image: none;
      }
    }
  }

  &-blue {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.blue.5');
        background-image: none;
      }
    }
  }

  &-orange {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.orange.5');
        background-image: none;
      }
    }
  }

  &-yellow {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.yellow.5');
        background-image: none;
      }
    }
  }

  &-navy {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.navy.5');
        background-image: none;
      }
    }
  }

  &-deepsky {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.deepSky.5');
        background-image: none;
      }
    }
  }

  &-froly {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.froly.5');
        background-image: none;
      }
    }
  }

  &-fuchsia {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.fuchsia.5');
        background-image: none;
      }
    }
  }

  &-fuchsiadark {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.fuchsiaDark.5');
        background-image: none;
      }
    }
  }

  &-royalblue {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.royalBlue.5');
        background-image: none;
      }
    }
  }

  &-deepmondiaviolet {
    &:before {
      background-color: transparent;
      background-image: none;
    }

    @include hocus() {
      &:before {
        background-color: theme('colors.deepMondiaViolet.5');
        background-image: none;
      }
    }
  }
}
