/* stylelint-disable */

body {
  @apply text-black text-base leading-150;

  font-weight: 400;

  &.travod-site {
    font-family: 'Fieldwork', sans-serif;
  }
}

.text {
  a {
    color: theme('colors.orange.70');

    @include hocus() {
      text-decoration: underline;
    }
  }

  b,
  strong {
    font-weight: 600;
    color: theme('colors.orange.70');
  }
}

.dotted-list {
  & > li {
    position: relative;
    padding-left: 1rem;

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      width: 6px;
      height: 6px;
      content: '';
      background-color: theme('colors.blue.default');
      border-radius: 50%;
    }
  }
}

.dashed-list {
  & > li {
    position: relative;
    padding-left: 1rem;

    &::before {
      position: absolute;
      top: 12px;
      left: 0;
      width: 6px;
      height: 2px;
      content: '';
      background-color: theme('colors.blue.default');
    }
  }
}

.numered-list {
  & > li {
    position: relative;
    padding-left: 1rem;
    counter-increment: item;

    &::before {
      position: absolute;
      left: 0;
      content: counter(item) '.';
      color: theme('colors.blue.default');
    }
  }
}

.landing-text-list li::before {
  position: absolute;
  top: 2px;
  left: 0;
  width: rem(24px);
  height: rem(24px);
  content: '';
  background-image: url('../../assets/svg/check-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* wordminds-site */
body.wordminds-site {
  font-family: 'Fieldwork', sans-serif;
  font-family: 'Avenir', sans-serif;
  font-weight: 600;

  h1,
  h2,
  h3,
  h4 {
    //font-weight: 900;
  }
}
