/* stylelint-disable */
@responsive {
  .tag {
    @apply text-14p text-white leading-143 font-semibold rounded py-1 px-2 bg-green;
    @extend .transition-cst;
  }

  .tag-blue {
    @apply bg-blue;
  }

  .tag-red {
    @apply bg-red;
  }

  .tag-gray {
    @apply text-black font-normal;
    @apply bg-gray;
  }

  .tag-white {
    @apply text-black font-normal;
    @apply bg-white;
  }

  .tag-large {
    @apply inline-block text-black text-14p font-normal leading-150 rounded;
    padding: rem(8px 12px);
    transition: 0.35s ease;
  }

  .tag-square {
    border-radius: 0;
  }

  @screen lg {
    .tag-large {
      padding: rem(10px 15px);
    }
  }
}
