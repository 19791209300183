.js-technology-swiper {
  &::before {
    display: none;

    @screen lg {
      position: absolute;
      top: 88px;
      left: 50%;
      display: block;
      width: 89.0625%;
      height: 4px;
      content: '';
      background-color: theme('colors.gray.default');
      transform: translate3d(-50%, 0, 0);
    }
  }

  .swiper-slide {
    /* box-shadow: rgba(0, 0, 0, 0.1) 0 3px 12px 0; */

    &:last-child {
      .group {
        &::before,
        &::after {
          display: none;
        }
      }
    }

    .group {
      border-radius: 4px;

      &::after,
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        height: 100%;
        content: '';

        @screen lg {
          display: none;
        }
      }

      &::before {
        z-index: 1;
        width: 20px;
        background-color: theme('colors.gray.default');
      }

      &::after {
        z-index: 2;
        width: 20px;
        clip-path: polygon(0% 100%, 100% 50%, 0% 0%);
        background-color: #fff;

        @screen lg {
          display: none;
        }
      }

      &.bg-white {
        &::before {
          background-color: theme('colors.gray.default');
        }

        &::after {
          background-color: #fff;
        }
      }

      &.bg-gray {
        &::before {
          background-color: #fff;
        }

        &::after {
          background-color: theme('colors.gray.default');
        }
      }
    }

    @media (min-width: theme('screens.lg')) {
      box-shadow: none;
    }
  }
}
