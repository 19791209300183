.ebook {
  &__cover {
    width: 100%;
    flex-shrink: 1;

    @screen md {
      position: absolute;
      top: 50%;
      left: rem(-260px);
      width: rem(230px);
      transform: rotate(-4deg) translateY(-50%);
    }

    @screen mlg {
      left: rem(-300px);
    }

    @screen lg {
      left: rem(-370px);
      width: rem(290px);
    }

    @screen xl {
      left: rem(-390px);
    }
  }

  &__content {
    @screen md {
      position: relative;
      margin-left: auto;
      display: block;
      width: (100% / 12) * 7;
    }

    @screen lg {
      width: (100% / 12) * 6;
    }
  }

  &__desc {
    @screen md {
      padding-left: 0;
      display: block;
      width: 100%;
      flex-shrink: 0;
    }
  }
}
