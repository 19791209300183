/* stylelint-disable */
.travod-employees {
  &__item {
    width: 20%;

    &:nth-child(n + 25) {
      display: none;
    }
  }

  &__badge {
    width: 40%;
  }

  @screen md {
    &__badge {
      width: 20%;
    }
  }

  @screen lg {
    &__item,
    &__badge {
      width: calc(100% / 8);
    }

    &__item {
      &:nth-child(n + 25) {
        display: block;
      }
    }
  }
}

.wordminds-site {
  .travod-employees {
    &__item {
      &:nth-child(n + 20) {
        display: none;
      }
    }

    @screen lg {
      &__item {
        &:nth-child(n + 16) {
          display: none;
        }
      }
    }
  }
}
