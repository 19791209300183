/* stylelint-disable */
@responsive {
  .more {
    display: inline-block;
    position: relative;
    border-bottom: 1px solid transparent;
    transition: 0.35s ease;
    color: theme('colors.orange.default');
  }

  .more:after {
    transition: 0.35s ease right;
    right: -20px;
    position: absolute;
    display: inline-block;
    content: '';
    background-image: url('../../assets/svg/rarr-orange.svg');
    background-repeat: no-repeat;
    background-position: center 50%;
    height: 100%;
    width: 0.75em;
    background-size: contain;
  }

  .more:hover,
  .more:focus,
  .more:active {
    border-color: theme('colors.orange.default');
    cursor: pointer;
  }

  .more:hover:after,
  .more:focus:after,
  .more:active:after {
    right: -24px;
  }

  .more-white {
    color: white;
  }

  .more-white:hover,
  .more-white:focus,
  .more-white:active {
    border-color: theme('colors.white.default');
  }

  .more-white:after {
    background-image: url('../../assets/svg/rarr-white.svg');
  }

  .wordminds-site {
    .more {
      color: theme('colors.green.wslight');
    }

    .more:after {
      background-image: url('../../assets/svg/rarr-green.svg');
      background-position: center 35%;
    }

    .more:hover,
    .more:focus,
    .more:active {
      border-color: theme('colors.green.wslight');
      cursor: pointer;
    }
  }
}
