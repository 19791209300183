/* stylelint-disable */

$heading-font: 'Helvetica Neue Bold', sans-serif;
$body-font: 'Helvetica Neue Medium', sans-serif;

$font-sizes: (
  h1: 3rem,
  h2: 1.25rem,
  h3: 1.25rem,
  h4: 1rem,
  h5: 1rem,
  h6: 1rem,
  body: 1rem,
);

$font-sizes-large: (
  h1: 7.5rem,
  h2: 2.75rem,
  h3: 2.75rem,
  h4: 1.374rem,
  h5: 1.375rem,
  h6: 1.375rem,
  body: 1.375rem,
);

$white: #ffffff;
$white-50: rgba(255, 255, 255, 0.5);
$text: #2c3041;
$blue: #194ef5;
$blue50: #8ca6fa;
$blue30: #bac9fc;
$blue10: #e8edfe;

$orange: #f7941d;
$orange50: #fbc98e;
$orange30: #fcdebb;
$orange10: #fef4e8;

$gray: #e6e7e8;
$gray50: #f2f3f3;
$gray30: #f7f7f8;
$gray10: #fcfcfc;

$red: #e82942;
$red50: #f394a0;
$red30: #f8bec6;
$red10: #fce9ec;

$green: #2db674;
$green50: #96dab9;
$green30: #c0e9d5;
$green10: #eaf7f1;

$medium: 30em;
$large: 60em;

/* Site */
$site-max-width: 123.2rem;
/* Box shadow */
$box-shadow-1: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
$box-shadow-2: 0 0 10px 0 rgba(0, 0, 0, 0.1);

@custom-media --mobile-large (min-width: 414px);
@custom-media --tablet (min-width: 640px);
@custom-media --tablet-large (min-width: 1024px);
@custom-media --desktop (min-width: 1366px);
@custom-media --desktop-large (min-width: 1600px);
@custom-media --desktop-extra-large (min-width: 1921px);
@custom-media --retina (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi);
@custom-media --ie (-ms-high-contrast: active), (-ms-high-contrast: none);
