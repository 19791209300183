/* stylelint-disable */

.wordminds-site {
  .text-orange,
  .hover\:text-orange:hover,
  .focus\:text-orange:focus,
  .group:hover .group-hover\:text-orange,
  .group:hover .group-hover\:text-orange-60 {
    color: theme('colors.green.wslight');
  }

  .text-orange-70 {
    color: theme('colors.green.wsnormal');
  }

  .text-blue {
    color: theme('colors.blue.wsdark');
  }

  .bg-gray-30,
  .bg-gray-50 {
    background-color: theme('colors.blue.wslight');
  }

  .btn-default {
    border-radius: 25px;
  }

  .footer,
  .subscribe {
    .btn-default.is-loading::after {
      background-image: url('../../assets/svg/spinner-green.svg');
    }

    .rounded {
      border-radius: 25px;
    }

    .rounded-r {
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
    }

    .bg-orange {
      background-color: theme('colors.green.wslight');
    }

    .bg-orange:hover {
      background-color: theme('colors.green.wsnormal');
    }
  }

  @screen md {
    .md\:bg-gray-30,
    .md\:bg-gray-50 {
      background-color: theme('colors.blue.wslight');
    }

    .md\:bg-white {
      background-color: #fff;
    }
  }

  @screen lg {
    .lg\:bg-gray-30,
    .lg\:bg-gray-50 {
      background-color: theme('colors.blue.wslight');
    }

    .lg\:bg-transparent {
      background-color: transparent;
    }

    .lg\:bg-white,
    .lg\:hover\:bg-white:hover {
      background-color: white;
    }

    .btn-default.is-loading::after {
      background-image: url('../../assets/svg/spinner-green.svg');
    }
  }
}

/* stylelint-disable end */
