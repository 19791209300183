/* stylelint-disable */

.swiper {
  &-container-wide {
    //overflow: visible !important;

  }

  &-slide {
    height: auto;

    &.border-box {
      box-sizing: border-box;
    }
  }

  &-pagination {
    position: relative;

    &-bullet {
      margin: 0 rem(5px);
      display: block;
      width: rem(20px);
      height: rem(20px);
      opacity: 1;
      background-color: white;
      border: rem(7px) solid theme('colors.blue.default');
      border-radius: 50%;

      &-active {
        width: rem(40px);
        background-color: theme('colors.blue.default');
        border-radius: 0;
      }
    }
  }

  &-button-prev,
  &-button-next {
    position: relative;
    right: unset;
    left: unset;
    margin-top: unset;
    width: 50px;
    height: 50px;
    color: unset;
    -webkit-tap-highlight-color: transparent;

    &::after {
      display: none;
    }
  }

  &-prev,
  &-next {
    &.swiper-button-disabled {
      opacity: 0.35;
      background-color: theme('colors.gray.defaut');
    }
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 1px;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  color: theme('colors.black.default');
  background-color: theme('colors.gray.default');
}

.wordminds-site {
  .swiper-pagination-bullet {
    border-color: theme('colors.blue.wsdark');
  }

  .swiper-pagination-bullet-active {
    background-color: theme('colors.blue.wsdark');
    border-color: theme('colors.blue.wsdark');
  }
}
