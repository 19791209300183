.ribbon-badge {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.8;
  text-align: center;
  color: #fff;
  background-color: theme('colors.orange.default');
  border-radius: 10px;

  @screen lg {
    top: 25px;
    left: -50px;
    padding: 2px 12px 1.5px 0;
    width: 200px;
    font-weight: bold;
    border-radius: 0;
    transform: rotate(-45deg);
  }
}
