.servise-card {
  @media (max-width: 640px) {
    border-bottom: 2px solid;

    @apply border-gray;

    &.is-first {
      border-top: 2px solid;

      @apply border-gray;
    }
  }
}
