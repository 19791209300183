/* stylelint-disable */

@responsive {
  .btn {
    @apply font-bold inline-flex justify-center items-center whitespace-no-wrap relative cursor-pointer;

    @extend .transition-cst;
  }

  .btn-square {
    @apply rounded;
  }

  .btn-round {
    @apply rounded-24p;
  }

  .btn-large {
    @apply h-50p px-8 text-sm leading-143 tracking-03p;
  }

  .btn-small {
    @apply px-4 h-8 text-xs leading-tight tracking-05p;
  }

  .btn-default {
    @apply text-white bg-orange;
  }

  .btn-default.is-loading {
    position: relative;
  }

  .btn-default.is-loading::after {
    position: absolute;
    top: 50%;
    right: 1rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: '';
    background-image: url('../../assets/svg/spinner-white.svg');
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateY(-50%);

    @screen lg {
      right: -2.5rem;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url('../../assets/svg/spinner-orange.svg');
    }
  }

  .btn-dark {
    @apply bg-black;
  }

  .btn-default:hover {
    @apply shadow-btn-default;
  }

  .btn-secondary {
    @apply text-blue border-2 border-blue bg-white;
  }

  .btn-secondary:hover {
    @apply bg-blue shadow-none text-white;
  }

  .btn-secondary-dark {
    @apply text-black border-2 border-black bg-transparent;
  }

  .btn-secondary-dark:hover {
    @apply bg-black shadow-none text-white;
  }

  .btn-ternary {
    @apply text-white border-2 bg-green border-green;
  }

  .btn-ternary:hover {
    @apply border-green-70 bg-green-70 shadow;
  }

  .btn-default-disabled {
    @apply bg-disable text-disable-primary pointer-events-none;
  }

  .btn-secondary-disabled {
    @apply bg-white border-disable text-disable-secondary pointer-events-none;
  }

  .btn-secondary-light {
    @apply text-white border-2 border-white bg-transparent;
  }

  .btn-secondary-light:hover {
    @apply text-black border-2 border-white bg-white;
  }

  .btn-light-disabled {
    @apply bg-transparent border-2 border-disable text-disable-secondary pointer-events-none;
  }

  .btn-secondary-lighter {
    @apply text-white border border-white bg-transparent;
  }

  .btn-secondary-lighter:hover {
    @apply text-black border border-white bg-white;
  }

  .btn-lighter-disabled {
    @apply bg-transparent border border-disable text-disable-secondary pointer-events-none;
  }

  .icon-left {
    @apply pl-28p;
  }

  .btn-phone {
    padding-right: rem(24px);
    padding-left: rem(38px);
    background-image: url('../../assets/svg/phone.svg');
    background-repeat: no-repeat;
    background-position: rem(16px) center;
  }

  .phone-icon {
    margin-right: rem(12px);
    display: inline-block;
    vertical-align: top;
  }

  .btn-down {
    border-radius: 0 0 2px 2px;
  }

  .btn-down-tel {
    padding-left: rem(26px);
  }
}

.wordminds-site {
  .btn {
    line-height: 21px;
    padding-top: 3px;
    text-transform: uppercase;
  }

  .btn-default {
    padding-top: 2px;

    @apply bg-green-wslight;

    &:hover {
      @apply bg-green-wsnormal;

      color: #fff;
      box-shadow: 0 2px 8px 0 rgba(47, 150, 56, 0.5);
    }
  }

  .btn-secondary {
    @apply text-green-wslight border-2 border-green-wslight bg-white;
  }

  .btn-secondary:hover {
    @apply bg-green-wsnormal shadow-none text-white border-green-wsnormal;
  }

  .btn-secondary-dark {
    @apply text-green-wsnormal border-2 border-green-wsnormal bg-white;
  }

  .btn-secondary:hover {
    @apply bg-green-wsnormal shadow-none text-white border-green-wsnormal;
  }

  .pricing-card:hover .action-button,
  .pricing-card:focus .action-button {
    @apply bg-green-wsnormal;
  }

  .btn-large {
    padding-top: 2px;
    border-radius: 1.5rem;
    line-height: 21px;
    font-weight: 700;
  }

  .btn-dark {
    @apply bg-black;
  }
}
