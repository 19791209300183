.swiper-about {
  .swiper-slide {
    @screen md {
      width: (100% / 12 * 4);
    }

    @screen lg {
      width: (100% / 12 * 3);
    }
  }
}
