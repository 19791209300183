.quote-crumbs {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.35s ease top;

  &__item {
    position: relative;
    display: block;
    line-height: 50px;
    font-weight: 600;
    background-color: theme('colors.gray.30');

    &::before {
      z-index: 1;
      right: -9px;
      border-width: 25px 0 25px 8px;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.12);
    }

    &::after {
      z-index: 2;
      right: -8px;
      border-width: 25px 0 25px 8px;
      border-color: transparent transparent transparent theme('colors.gray.30');
    }

    &:not(:last-child)::before,
    &:not(:last-child)::after {
      position: absolute;
      top: 0;
      display: block;
      width: 8px;
      height: 50px;
      content: '';
      border-style: solid;
    }

    &:not(.is-current) {
      color: rgba(44, 48, 65, 0.3);
    }

    &:last-child.is-active {
      color: theme('colors.green.default');
    }

    &:not(:disabled):hover {
      color: #fff;
      background-color: theme('colors.orange.default');
      transition: 0.35s ease background-color;

      &::after {
        border-color: transparent transparent transparent theme('colors.orange.default');
        transition: 0.35s ease border-color;
      }
    }

    &.is-current {
      background-color: #fff;

      &::after {
        z-index: 2;
        right: -8px;
        border-width: 25px 0 25px 8px;
        border-color: transparent transparent transparent #fff;
      }
    }

    &.is-active {
      color: theme('colors.orange.default');
      background-color: #fff;
    }
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    background-image: linear-gradient(
      to right,
      #fff 0,
      #fff 50%,
      theme('colors.gray.30') 50%,
      theme('colors.gray.30') 100%
    );
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);

    /* stylelint-disable */
    > * {
      box-shadow: none;
    }
    /* stylelint-enable */
  }
}

.wordminds-site {
  .quote-crumbs {
    &__item {
      &:not(.is-current) {
        background-color: theme('colors.blue.wslight');
      }

      &:last-child.is-active {
        color: theme('colors.green.wsnormal');
      }

      &:not(:disabled):hover {
        background-color: theme('colors.green.wslight');

        &::after {
          border-color: transparent transparent transparent theme('colors.green.wslight');
        }
      }

      &.is-active {
        color: theme('colors.green.wslight');

        &:hover {
          color: #fff;
        }
      }
    }

    &.is-sticky {
      background-image: linear-gradient(
        to right,
        #fff 0,
        #fff 50%,
        theme('colors.blue.wslight') 50%,
        theme('colors.blue.wslight') 100%
      );
    }
  }
}
