/* purgecss start ignore */
.services__list {
  /* @screen lg {
    min-height: rem(407px);
  } */
}

.services__item {
  &.is-active {
    padding-bottom: 1.25rem;
    background-color: theme('colors.white.default');

    @media (min-width: theme('screens.lg')) {
      padding-bottom: 0;
      display: flex;
      background-color: transparent;
    }
  }

  &.is-first {
    .services__title {
      border-top-color: transparent;
    }
  }

  &.is-last.is-active {
    @media (max-width: theme('screens.lg')) {
      padding-bottom: 0;
    }

    &::after {
      @media (max-width: theme('screens.lg')) {
        margin-top: 1.25rem;
        display: block;
        width: 100%;
        height: 1px;
        content: '';
        background-color: theme('colors.gray.default');
      }
    }
  }
}

.services__title {
  border-top: 2px solid theme('colors.gray.default');

  &.is-active {
    border-top-color: transparent;

    & > span {
      transform: rotate(-180deg);

      @media (min-width: theme('screens.lg')) {
        transform: unset;
      }
    }
  }
}

/* purgecss end ignore */
