.pricing-card {
  &:hover,
  &:focus {
    .action-button {
      background-color: theme('colors.orange.default');
    }
  }

  &.disabled .alerts {
    display: block;
  }

  &.disabled + .overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.3);
    filter: blur(10px);
  }
}
